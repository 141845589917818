import MetaDataState from './MetaDataState';

export const defaultState: MetaDataState = {
  isLoading: false,
  selectedCountry: '',
  selectedVendor: '',
  selectedVendorName: '',
  userEmail: '',
  featureActivation: {
    clickCollectEnabled: false,
    flexDeliveryEnabled: false,
    regularDeliveryEnabled: false,
  },
  listVendors: [],
  groups: [],
  userData: {
    email: null,
    country: null,
    id: null,
    affiliation: null,
    preferredLanguage: null,
  },
  lastUrl: '',
};

export const metadataStorePersistWhiteList: (keyof MetaDataState)[] = [
  'selectedCountry',
  'selectedVendor',
  'selectedVendorName',
  'userEmail',
  'featureActivation',
  'listVendors',
  'groups',
  'userData',
  'lastUrl',
];

export type PersistedMetaDataState = Pick<
  MetaDataState,
  typeof metadataStorePersistWhiteList[number]
>;
