/* eslint-disable camelcase */
export const APP_NAME = 'Delivery';
export const APP_ID = 'DELIVERY_WINDOW';

export const HierarchyGroups = [
  'LOATDeliveryWindow.BEES Admin',
  'LOATDeliveryWindow.Delivery window Full admin',
  'LOATDeliveryWindow.Date manager',
  'LOATDeliveryWindow.DC manager',
  'LOATDeliveryWindow.Client manager',
  'LOATDeliveryWindow.Flexible window manager',
  'LOATDeliveryWindow.Wholesaler manager',
  'LOATDeliveryWindow.Holiday manager',
  'LOATDeliveryWindow.Delivery capacity availability manager',
  'LOATDeliveryWindow.C&C manager',
  'LOATDeliveryWindow.C&C parameters manager',
  'LOATDeliveryWindow.C&C consumption manager',
  'LOATDeliveryWindow.CSV download manager',
  'LOATDeliveryWindow.Delivery window viewer',
  'LOATDeliveryWindow.C&C viewer',
  'LOATDeliveryWindow.C&C parameters viewer',
  'LOATDeliveryWindow.C&C consumption viewer',
  'LOATDeliveryWindow.Delivery capacity availability viewer',
];

export const NO_HIERARCHY_GROUP_FOUND = 'No valid user permission group';

export const NO_USER_AFFILIATION = 'No affiliation';

export enum PagesIDs {
  DDCS = 'DDCS',
  DDCS_EDIT = 'DDCS_EDIT',
  DDC_CUSTOM_SETTINGS = 'DDC_CUSTOM_SETTINGS',
  HOLIDAY = 'HOLIDAY',
  DDCS_RULES = 'DDCS_RULES',
  DELIVERY_WINDOW = 'DELIVERY_WINDOW',
  CLIENT_EDIT = 'CLIENT_EDIT',
  CLIENT_RULES = 'CLIENT_RULES',
  UPLOADS_AND_DOWNLOADS = 'UPLOADS_AND_DOWNLOADS',
  UPLOAD_CLIENT_BASE = 'UPLOAD_CLIENT_BASE',
  UPLOAD_RULES_BASE = 'UPLOAD_RULES_BASE',
  CAPACITY_PARAMETERS = 'CAPACITY_PARAMETERS',
  EDIT_CAPACITY_PARAMETERS = 'EDIT_CAPACITY_PARAMETERS',
}

export const PageRouteData = {
  [PagesIDs.DDCS]: {
    page_label: 'DDCs Management',
    page_name: PagesIDs.DDCS,
  },
  [PagesIDs.DDCS_EDIT]: {
    page_label: 'DDCs Management Editing',
    page_name: PagesIDs.DDCS_EDIT,
  },
  [PagesIDs.DDC_CUSTOM_SETTINGS]: {
    page_label: 'DDC Custom Settings Editing',
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
  },
  [PagesIDs.HOLIDAY]: {
    page_label: 'Manage Holidays',
    page_name: PagesIDs.HOLIDAY,
  },
  [PagesIDs.DDCS_RULES]: {
    page_label: 'DDCs Exception Rules',
    page_name: PagesIDs.DDCS_RULES,
  },
  [PagesIDs.DELIVERY_WINDOW]: {
    page_label: 'Delivery Windows',
    page_name: PagesIDs.DELIVERY_WINDOW,
  },
  [PagesIDs.CLIENT_EDIT]: {
    page_label: 'Edit POC-level settings',
    page_name: PagesIDs.CLIENT_EDIT,
  },
  [PagesIDs.CLIENT_RULES]: {
    page_label: 'Clients Exception Rules',
    page_name: PagesIDs.CLIENT_RULES,
  },
  [PagesIDs.UPLOADS_AND_DOWNLOADS]: {
    page_label: 'Upload and Download Files',
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
  },
  [PagesIDs.UPLOAD_CLIENT_BASE]: {
    page_label: 'Upload Client Files',
    page_name: PagesIDs.UPLOAD_CLIENT_BASE,
  },
  [PagesIDs.UPLOAD_RULES_BASE]: {
    page_label: 'Upload Rules Files',
    page_name: PagesIDs.UPLOAD_RULES_BASE,
  },
  [PagesIDs.CAPACITY_PARAMETERS]: {
    page_label: 'Delivery Capacity Availability Parameters',
    page_name: PagesIDs.CAPACITY_PARAMETERS,
  },
  [PagesIDs.EDIT_CAPACITY_PARAMETERS]: {
    page_label: 'Delivery Capacity Availability Parameter Creation',
    page_name: PagesIDs.EDIT_CAPACITY_PARAMETERS,
  },
};

export enum ElementLocationTypes {
  table = 'TABLE',
  page = 'PAGE',
  floatingMenu = 'FLOATING_MENU',
  section = 'SECTION',
  tab = 'TAB',
  modal = 'MODAL',
  card = 'CARD',
}

export enum ElementLocationNames {
  ddcList = 'DDC_LIST',
  ddcsEdit = 'DDCS_EDIT',
  holidays = 'HOLIDAYS',
  ddcsRules = 'DDCS_RULES',
  pocList = 'POC_LIST',
  clientEdit = 'CLIENT_EDIT',
  deliveryWindowSection = 'DELIVERY_WINDOW_SECTION',
  resendDeliveryWindows = 'RESEND_DELIVERY_WINDOWS',
  clientRules = 'CLIENT_RULES',
  uploadAndDownloads = 'UPLOAD _AND_DOWNLOADS',
  clientsTab = 'CLIENTS_TAB',
  clientsUploadTab = 'CLIENTS_UPLOAD_TAB',
  uploadClientBase = 'UPLOAD_CLIENT_BASE',
  uploadRulesBase = 'UPLOAD_RULES_BASE',
  rulesTab = 'RULES_TAB',
  rulesUploadTab = 'RULES_UPLOAD_TAB',
  clientsDownloadTab = 'CLIENTS_DOWNLOAD_TAB',
  requestClientsBaseModal = 'REQUEST_CLIENTS_BASE_MODAL',
  rulesDownloadTab = 'RULES_DOWNLOAD_TAB',
  requestRulesBaseModal = 'REQUEST_RULES_BASE_MODAL',
  ddcs = 'DDCS',
  editDeliveryCapacityAvailabilityParameter = 'EDIT_DELIVERY_CAPACITY_AVAILABILITY_PARAMETER',
  capacityParameters = 'CAPACITY_PARAMETERS',
  capacityParameterList = 'CAPACITY_PARAMETER_LIST',
  deleteCapacityParameterModal = 'DELETE_CAPACITY_PARAMETER_MODAL',
  automatedDateCard = 'AUTOMATED_DATE_CARD',
  manualDatesCard = 'MANUAL_DATES_CARD',
  automatedWithoutDates = 'AUTOMATED_WITHOUT_DATES',
  deleteCustomSettingsModal = 'DELETE_CUSTOM_SETTINGS_MODAL',
  customSettings = 'CUSTOM_SETTINGS',
  customSettingsWindowRange = 'CUSTOM_SETTINGS_WINDOW_RANGE',
  customSettingsHolidayStrategy = 'CUSTOM_SETTINGS_HOLIDAY_STRATEGY',
  customSettingsAutomaticRules = 'CUSTOM_SETTINGS_AUTOMATIC_RULES',
  customSettingsExpirationBehavior = 'CUSTOM_SETTINGS_EXPIRATION_BEHAVIOR',
  customSettingsExpirationDay = 'CUSTOM_SETTINGS_EXPIRATION_DAY',
  customSettingsExpirationTime = 'CUSTOM_SETTINGS_EXPIRATION_TIME',
}

export enum ElementTypes {
  button = 'BUTTON',
  tab = 'TAB',
  accordion = 'ACCORDION',
  radioButton = 'RADIO_BUTTON',
  link = 'LINK',
  switch = 'SWITCH',
  quantifier = 'QUANTIFIER',
  chip = 'CHIP',
  selector = 'SELECTOR',
  timePicker = 'TIME_PICKER',
}

export enum ElementNames {
  editDdc = 'EDIT_DDC',
  saveDdc = 'SAVE_DDC',
  manageHolidays = 'MANAGE_HOLIDAYS',
  ddcManageExceptionRules = 'DDC_MANAGE_EXCEPTION_RULES',
  viewClickAndCollectConsume = 'VIEW_CLICK_AND_COLLECT_CONSUME',
  saveHoliday = 'SAVE_HOLIDAY',
  saveDdcRules = 'SAVE_DDC_RULES',
  editPoc = 'EDIT_POC',
  loadDeliveryWindowList = 'LOAD_DELIVERY_WINDOW_LIST',
  pocManageExceptionRules = 'POC_MANAGE_EXCEPTION_RULES',
  saveClient = 'SAVE_CLIENT',
  ddcSettings = 'DDC_SETTINGS',
  pocResendWindows = 'POC_RESEND_WINDOWS',
  saveClientRules = 'SAVE_CLIENT_RULES',
  clientsTab = 'CLIENTS_TAB',
  clientsUploadTab = 'CLIENTS_UPLOAD_TAB',
  clientsDownloadTab = 'CLIENTS_DOWNLOAD_TAB',
  clientsUploadStarted = 'CLIENTS_UPLOAD_STARTED',
  clientsFileInstructions = 'CLIENTS_FILE_INSTRUCTIONS',
  clientsDownloadTemplate = 'CLIENTS_DOWNLOAD_TEMPLATE',
  clientsFileUpload = 'CLIENTS_FILE_UPLOAD',
  ddcOrClients = 'DDC_OR_CLIENTS',
  rulesFileInstructions = 'RULES_FILE_INSTRUCTIONS',
  rulesDownloadTemplate = 'RULES_DOWNLOAD_TEMPLATE',
  rulesFileUpload = 'RULES_FILE_UPLOAD',
  rulesTab = 'RULES_TAB',
  rulesUploadTab = 'RULES_UPLOAD_TAB',
  rulesDownloadTab = 'RULES_DOWNLOAD_TAB',
  rulesUploadStarted = 'RULES_UPLOAD_STARTED',
  downloadClientsErrorFile = 'DOWNLOAD_CLIENTS_ERROR_FILE',
  downloadClientsUploadedFile = 'DOWNLOAD_CLIENTS_UPLOADED_FILE',
  downloadRulesErrorFile = 'DOWNLOAD_RULES_ERROR_FILE',
  downloadRulesUploadedFile = 'DOWNLOAD_RULES_UPLOADED_FILE',
  downloadClientsAuditFile = 'DOWNLOAD_CLIENTS_AUDIT_FILE',
  downloadClientsBaseFile = 'DOWNLOAD_CLIENTS_BASE_FILE',
  requestClientsBaseFile = 'REQUEST_CLIENTS_BASE_FILE',
  downloadRulesAuditFile = 'DOWNLOAD_RULES_AUDIT_FILE',
  downloadRulesBaseFile = 'DOWNLOAD_RULES_BASE_FILE',
  requestRulesBaseFile = 'REQUEST_RULES_BASE_FILE',
  audiences = 'AUDIENCES',
  saveCapacityParameter = 'SAVE_CAPACITY_PARAMETER',
  strategyProgressTab = 'STRATEGY_PROGRESS_TAB',
  capacityParameterList = 'CAPACITY_PARAMETER_LIST',
  startCapacityParameterCreation = 'START_CAPACITY_PARAMETER_CREATION',
  deleteCapacityParameter = 'DELETE_CAPACITY_PARAMETER',
  editCapacityParameter = 'EDIT_CAPACITY_PARAMETER',
  confirmDeleteCapacityParameter = 'CONFIRM_DELETE_CAPACITY_PARAMETER',
  cancel = 'CANCEL',
  delete = 'DELETE',
  exportPocsAutomatedDates = 'EXPORT_POCS_AUTOMATED_DATES',
  exportPocsManualDates = 'EXPORT_POCS_MANUAL_DATES',
  exportPocsWithoutDates = 'EXPORT_POCS_WITHOUT_DATES',
  redirectUploadAndDownloads = 'REDIRECT_UPLOAD_AND_DOWNLOADS',
  ddcResendWindows = 'DDC_RESEND_WINDOWS',
  // ddc edit tabs
  ddcEditDeliveryWindowTab = 'DELIVERY_WINDOW_TAB',
  ddcEditOperationTab = 'DDC_OPERATION_TAB',
  ddcEditClickAndCollectTab = 'CLICK_AND_COLLECT_TAB',
  ddcEditDetailsTab = 'DDC_DETAILS_TAB',
  ddcEditDeliveryRangeTab = 'DDC_DELIVERY_RANGE_TAB',
  // custom settings
  defaultSettingsView = 'DEFAULT_SETTINGS_VIEW',
  customSettingsView = 'CUSTOM_SETTINGS_VIEW',
  createCustomSetting = 'CREATE_CUSTOM_SETTING',
  editCustomSetting = 'EDIT_CUSTOM_SETTING',
  trashCanCustomSetting = 'TRASH_CAN_CUSTOM_SETTING',
  confirmDeleteCustomSettings = 'CONFIRM_DELETE_CUSTOM_SETTINGS',
  cancelDeleteCustomSettings = 'CANCEL_DELETE_CUSTOM_SETTINGS',
  deleteCustomSettings = 'DELETE_SETTINGS',
  saveCustomSettings = 'SAVE_SETTINGS',
  confirmDeleteCustomSettingsInternal = 'CONFIRM_DELETE_CUSTOM_SETTINGS_INTERNAL',
  cancelDeleteCustomSettingsInternal = 'CANCEL_DELETE_CUSTOM_SETTINGS_INTERNAL',
  deliveryWindowParametersCustomSettingsSwitch = 'WINDOW_RANGE_SWITCH',
  holidayCustomSettingsSwitch = 'HOLIDAY_STRATEGY_SWITCH',
  automaticRulesCustomSettingsSwitch = 'AUTOMATIC_RULES_SWITCH',
  expirationCustomSettingsSwitch = 'EXPIRATION_BEHAVIOR_SWITCH',
  windowRangeQuantifier = 'WINDOW_RANGE_QUANTIFIER',
  holidayBehavior = 'HOLIDAY_BEHAVIOR',
  workingDaysunday = 'WORKING_DAY_SUNDAY',
  workingDaymonday = 'WORKING_DAY_MONDAY',
  workingDaytuesday = 'WORKING_DAY_TUESDAY',
  workingDaywednesday = 'WORKING_DAY_WEDNESDAY',
  workingDaythursday = 'WORKING_DAY_THURSDAY',
  workingDayfriday = 'WORKING_DAY_FRIDAY',
  workingDaysaturday = 'WORKING_DAY_SATURDAY',
  calculationRulesunday = 'CALCULATION_RULE_SUNDAY',
  calculationRulemonday = 'CALCULATION_RULE_MONDAY',
  calculationRuletuesday = 'CALCULATION_RULE_TUESDAY',
  calculationRulewednesday = 'CALCULATION_RULE_WEDNESDAY',
  calculationRulethursday = 'CALCULATION_RULE_THURSDAY',
  calculationRulefriday = 'CALCULATION_RULE_FRIDAY',
  calculationRulesaturday = 'CALCULATION_RULE_SATURDAY',
  expirationTimesunday = 'EXPIRATION_TIME_SUNDAY',
  expirationTimemonday = 'EXPIRATION_TIME_MONDAY',
  expirationTimetuesday = 'EXPIRATION_TIME_TUESDAY',
  expirationTimewednesday = 'EXPIRATION_TIME_WEDNESDAY',
  expirationTimethursday = 'EXPIRATION_TIME_THURSDAY',
  expirationTimefriday = 'EXPIRATION_TIME_FRIDAY',
  expirationTimesaturday = 'EXPIRATION_TIME_SATURDAY',
  // automatic parameters
  automaticParametersdeliveryDaysAfterVisitDay = 'BDR_VISIT',
  automaticParametersdistanceRange = 'NEARBY_POC',
  automaticParametersdynamicDaysOfWeek = 'CURRENT_DAY',
  automaticParametersdeliveryDaysOfWeek = 'FIXED_DAYS',
  automaticParametersClearRule = 'CLEAR_RULE',
  automaticParametersFrequencyOnlyForNewClients = 'FREQUENCY_ONLY_FOR_NEW_CLIENTS',
  automaticParametersApplyRule1Only = 'APPLY_RULE1_ONLY',
  automaticParametersNearbyPocQuantifier = 'NEARBY_POC_QUANTIFIER',
  automaticParametersBdrVisit0 = 'BDR_VISIT_+0',
  automaticParametersBdrVisit1 = 'BDR_VISIT_+1',
  automaticParametersBdrVisit2 = 'BDR_VISIT_+2',
  automaticParametersBdrVisit3 = 'BDR_VISIT_+3',
  automaticParametersBdrVisit4 = 'BDR_VISIT_+4',
  automaticParametersBdrVisit5 = 'BDR_VISIT_+5',
  automaticParametersBdrVisit6 = 'BDR_VISIT_+6',
  automaticParametersFrequencyweekly = 'EVERY_WEEK',
  automaticParametersFrequencybiweekly = 'EVERY_TWO_WEEKS',
  automaticParametersFrequencytriweekly = 'EVERY_THREE_WEEKS',
  automaticParametersFrequencymonthly = 'EVERY_FOUR_WEEKS',
  automaticParametersCurrentDay0 = 'CURRENT_DAY+0',
  automaticParametersCurrentDay1 = 'CURRENT_DAY+1',
  automaticParametersCurrentDay2 = 'CURRENT_DAY+2',
  automaticParametersCurrentDay3 = 'CURRENT_DAY+3',
  automaticParametersCurrentDay4 = 'CURRENT_DAY+4',
  automaticParametersCurrentDay5 = 'CURRENT_DAY+5',
  automaticParametersCurrentDay6 = 'CURRENT_DAY+6',
  automaticParametersFixedDaysunday = 'FIXED_DAY_SUNDAY',
  automaticParametersFixedDaymonday = 'FIXED_DAY_MONDAY',
  automaticParametersFixedDaytuesday = 'FIXED_DAY_TUESDAY',
  automaticParametersFixedDaywednesday = 'FIXED_DAY_WEDNESDAY',
  automaticParametersFixedDaythursday = 'FIXED_DAY_THURSDAY',
  automaticParametersFixedDayfriday = 'FIXED_DAY_FRIDAY',
  automaticParametersFixedDaysaturday = 'FIXED_DAY_SATURDAY',
}

type Element = Partial<
  Record<
    ElementNames,
    {
      is_core_event: boolean;
      page_name: PagesIDs;
      element_location_type: ElementLocationTypes;
      element_location_name: ElementLocationNames;
      element_label: string;
      element_name: ElementNames;
      element_type: ElementTypes;
    }
  >
>;

export const elementClicked: Element = {
  [ElementNames.editDdc]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS,
    element_location_type: ElementLocationTypes.table,
    element_location_name: ElementLocationNames.ddcList,
    element_label: 'Edit DDC',
    element_name: ElementNames.editDdc,
    element_type: ElementTypes.button,
  },
  [ElementNames.saveDdc]: {
    is_core_event: true,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.ddcsEdit,
    element_label: 'Save changes',
    element_name: ElementNames.saveDdc,
    element_type: ElementTypes.button,
  },
  [ElementNames.manageHolidays]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS,
    element_location_type: ElementLocationTypes.floatingMenu,
    element_location_name: ElementLocationNames.ddcList,
    element_label: 'Manage Holidays',
    element_name: ElementNames.manageHolidays,
    element_type: ElementTypes.button,
  },
  [ElementNames.ddcManageExceptionRules]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS,
    element_location_type: ElementLocationTypes.floatingMenu,
    element_location_name: ElementLocationNames.ddcList,
    element_label: 'Manage Inclusion/Exclusion Rules',
    element_name: ElementNames.ddcManageExceptionRules,
    element_type: ElementTypes.button,
  },
  [ElementNames.viewClickAndCollectConsume]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS,
    element_location_type: ElementLocationTypes.floatingMenu,
    element_location_name: ElementLocationNames.ddcList,
    element_label: 'View Click & Collect consume',
    element_name: ElementNames.viewClickAndCollectConsume,
    element_type: ElementTypes.button,
  },
  [ElementNames.saveHoliday]: {
    is_core_event: true,
    page_name: PagesIDs.HOLIDAY,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.holidays,
    element_label: 'Save Settings',
    element_name: ElementNames.saveHoliday,
    element_type: ElementTypes.button,
  },
  [ElementNames.saveDdcRules]: {
    is_core_event: true,
    page_name: PagesIDs.DDCS_RULES,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.ddcsRules,
    element_label: 'Confirm Paramenters',
    element_name: ElementNames.saveDdcRules,
    element_type: ElementTypes.button,
  },
  [ElementNames.editPoc]: {
    is_core_event: false,
    page_name: PagesIDs.DELIVERY_WINDOW,
    element_location_type: ElementLocationTypes.floatingMenu,
    element_location_name: ElementLocationNames.pocList,
    element_label: 'Edit',
    element_name: ElementNames.editPoc,
    element_type: ElementTypes.button,
  },
  [ElementNames.loadDeliveryWindowList]: {
    is_core_event: false,
    page_name: PagesIDs.DELIVERY_WINDOW,
    element_location_type: ElementLocationTypes.floatingMenu,
    element_location_name: ElementLocationNames.pocList,
    element_label: 'Show delivery window',
    element_name: ElementNames.loadDeliveryWindowList,
    element_type: ElementTypes.button,
  },
  [ElementNames.pocManageExceptionRules]: {
    is_core_event: false,
    page_name: PagesIDs.DELIVERY_WINDOW,
    element_location_type: ElementLocationTypes.floatingMenu,
    element_location_name: ElementLocationNames.pocList,
    element_label: 'Manage rules',
    element_name: ElementNames.pocManageExceptionRules,
    element_type: ElementTypes.button,
  },
  [ElementNames.saveClient]: {
    is_core_event: true,
    page_name: PagesIDs.CLIENT_EDIT,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.clientEdit,
    element_label: 'Save settings',
    element_name: ElementNames.saveClient,
    element_type: ElementTypes.button,
  },
  [ElementNames.ddcSettings]: {
    is_core_event: false,
    page_name: PagesIDs.CLIENT_EDIT,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.deliveryWindowSection,
    element_label: 'Go to DDC Management',
    element_name: ElementNames.ddcSettings,
    element_type: ElementTypes.button,
  },
  [ElementNames.pocResendWindows]: {
    is_core_event: false,
    page_name: PagesIDs.CLIENT_EDIT,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.resendDeliveryWindows,
    element_label: 'Resend window',
    element_name: ElementNames.pocResendWindows,
    element_type: ElementTypes.button,
  },
  [ElementNames.saveClientRules]: {
    is_core_event: true,
    page_name: PagesIDs.CLIENT_RULES,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.clientRules,
    element_label: 'Confirm Paramenters',
    element_name: ElementNames.saveClientRules,
    element_type: ElementTypes.button,
  },
  [ElementNames.clientsTab]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.uploadAndDownloads,
    element_label: 'Clients',
    element_name: ElementNames.clientsTab,
    element_type: ElementTypes.tab,
  },
  [ElementNames.clientsUploadTab]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.clientsTab,
    element_label: 'Manage',
    element_name: ElementNames.clientsUploadTab,
    element_type: ElementTypes.tab,
  },
  [ElementNames.clientsDownloadTab]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.clientsTab,
    element_label: 'Package data requests',
    element_name: ElementNames.clientsDownloadTab,
    element_type: ElementTypes.tab,
  },
  [ElementNames.clientsUploadStarted]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.clientsUploadTab,
    element_label: 'Upload clients file',
    element_name: ElementNames.clientsUploadStarted,
    element_type: ElementTypes.button,
  },
  [ElementNames.clientsFileInstructions]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOAD_CLIENT_BASE,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.uploadClientBase,
    element_label: 'Review file instructions',
    element_name: ElementNames.clientsFileInstructions,
    element_type: ElementTypes.accordion,
  },
  [ElementNames.clientsDownloadTemplate]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOAD_CLIENT_BASE,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.uploadClientBase,
    element_label: 'Download template',
    element_name: ElementNames.clientsDownloadTemplate,
    element_type: ElementTypes.button,
  },
  [ElementNames.clientsFileUpload]: {
    is_core_event: true,
    page_name: PagesIDs.UPLOAD_CLIENT_BASE,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.uploadClientBase,
    element_label: 'Upload',
    element_name: ElementNames.clientsFileUpload,
    element_type: ElementTypes.button,
  },
  [ElementNames.ddcOrClients]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOAD_RULES_BASE,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.uploadRulesBase,
    element_label: 'DDC or Clients',
    element_name: ElementNames.ddcOrClients,
    element_type: ElementTypes.radioButton,
  },
  [ElementNames.rulesFileInstructions]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOAD_RULES_BASE,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.uploadRulesBase,
    element_label: 'Review file instructions',
    element_name: ElementNames.rulesFileInstructions,
    element_type: ElementTypes.accordion,
  },
  [ElementNames.rulesDownloadTemplate]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOAD_RULES_BASE,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.uploadRulesBase,
    element_label: 'Download template',
    element_name: ElementNames.rulesDownloadTemplate,
    element_type: ElementTypes.button,
  },
  [ElementNames.rulesFileUpload]: {
    is_core_event: true,
    page_name: PagesIDs.UPLOAD_RULES_BASE,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.uploadRulesBase,
    element_label: 'Upload',
    element_name: ElementNames.rulesFileUpload,
    element_type: ElementTypes.button,
  },
  [ElementNames.rulesTab]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.uploadAndDownloads,
    element_label: 'Rules',
    element_name: ElementNames.rulesTab,
    element_type: ElementTypes.tab,
  },
  [ElementNames.rulesUploadTab]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.rulesTab,
    element_label: 'Manage',
    element_name: ElementNames.rulesUploadTab,
    element_type: ElementTypes.tab,
  },
  [ElementNames.rulesDownloadTab]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.rulesTab,
    element_label: 'Package data requests',
    element_name: ElementNames.rulesDownloadTab,
    element_type: ElementTypes.tab,
  },
  [ElementNames.rulesUploadStarted]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.rulesUploadTab,
    element_label: 'Upload rules file',
    element_name: ElementNames.rulesUploadStarted,
    element_type: ElementTypes.button,
  },
  [ElementNames.downloadClientsErrorFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.clientsUploadTab,
    element_label: 'Download errors',
    element_name: ElementNames.downloadClientsErrorFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.downloadClientsUploadedFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.clientsUploadTab,
    element_label: 'Download file',
    element_name: ElementNames.downloadClientsUploadedFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.downloadRulesErrorFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.rulesUploadTab,
    element_label: 'Download errors',
    element_name: ElementNames.downloadRulesErrorFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.downloadRulesUploadedFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.rulesUploadTab,
    element_label: 'Download file',
    element_name: ElementNames.downloadRulesUploadedFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.downloadClientsAuditFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.table,
    element_location_name: ElementLocationNames.clientsDownloadTab,
    element_label: 'Download file',
    element_name: ElementNames.downloadClientsAuditFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.downloadClientsBaseFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.table,
    element_location_name: ElementLocationNames.clientsDownloadTab,
    element_label: 'Download file',
    element_name: ElementNames.downloadClientsBaseFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.requestClientsBaseFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.modal,
    element_location_name: ElementLocationNames.requestClientsBaseModal,
    element_label: 'Confirm',
    element_name: ElementNames.requestClientsBaseFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.downloadRulesAuditFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.table,
    element_location_name: ElementLocationNames.rulesDownloadTab,
    element_label: 'Download file',
    element_name: ElementNames.downloadRulesAuditFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.downloadRulesBaseFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.table,
    element_location_name: ElementLocationNames.rulesDownloadTab,
    element_label: 'Download file',
    element_name: ElementNames.downloadRulesBaseFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.requestRulesBaseFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.modal,
    element_location_name: ElementLocationNames.requestRulesBaseModal,
    element_label: 'Confirm',
    element_name: ElementNames.requestRulesBaseFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.audiences]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.ddcs,
    element_label: 'Go to Audiences',
    element_name: ElementNames.audiences,
    element_type: ElementTypes.button,
  },
  [ElementNames.saveCapacityParameter]: {
    is_core_event: true,
    page_name: PagesIDs.EDIT_CAPACITY_PARAMETERS,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.editDeliveryCapacityAvailabilityParameter,
    element_label: 'Save',
    element_name: ElementNames.saveCapacityParameter,
    element_type: ElementTypes.button,
  },
  [ElementNames.strategyProgressTab]: {
    is_core_event: false,
    page_name: PagesIDs.CAPACITY_PARAMETERS,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.capacityParameters,
    element_label: 'Strategy Progress',
    element_name: ElementNames.strategyProgressTab,
    element_type: ElementTypes.tab,
  },
  [ElementNames.capacityParameterList]: {
    is_core_event: false,
    page_name: PagesIDs.CAPACITY_PARAMETERS,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.capacityParameters,
    element_label: 'Availability parameter list',
    element_name: ElementNames.capacityParameterList,
    element_type: ElementTypes.tab,
  },
  [ElementNames.startCapacityParameterCreation]: {
    is_core_event: false,
    page_name: PagesIDs.CAPACITY_PARAMETERS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.capacityParameterList,
    element_label: 'Add an Availability Parameter',
    element_name: ElementNames.startCapacityParameterCreation,
    element_type: ElementTypes.tab,
  },
  [ElementNames.deleteCapacityParameter]: {
    is_core_event: false,
    page_name: PagesIDs.CAPACITY_PARAMETERS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.capacityParameterList,
    element_label: 'Remove Availability Parameter',
    element_name: ElementNames.deleteCapacityParameter,
    element_type: ElementTypes.tab,
  },
  [ElementNames.editCapacityParameter]: {
    is_core_event: false,
    page_name: PagesIDs.CAPACITY_PARAMETERS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.capacityParameterList,
    element_label: 'Edit Availability Parameter',
    element_name: ElementNames.editCapacityParameter,
    element_type: ElementTypes.tab,
  },
  [ElementNames.confirmDeleteCapacityParameter]: {
    is_core_event: false,
    page_name: PagesIDs.CAPACITY_PARAMETERS,
    element_location_type: ElementLocationTypes.modal,
    element_location_name: ElementLocationNames.deleteCapacityParameterModal,
    element_label: 'Delete',
    element_name: ElementNames.confirmDeleteCapacityParameter,
    element_type: ElementTypes.button,
  },
  [ElementNames.cancel]: {
    is_core_event: false,
    page_name: PagesIDs.CAPACITY_PARAMETERS,
    element_location_type: ElementLocationTypes.modal,
    element_location_name: ElementLocationNames.deleteCapacityParameterModal,
    element_label: 'Go back',
    element_name: ElementNames.cancel,
    element_type: ElementTypes.button,
  },
  [ElementNames.exportPocsAutomatedDates]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.card,
    element_location_name: ElementLocationNames.automatedDateCard,
    element_label: 'Export list',
    element_name: ElementNames.exportPocsAutomatedDates,
    element_type: ElementTypes.link,
  },
  [ElementNames.exportPocsManualDates]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.card,
    element_location_name: ElementLocationNames.manualDatesCard,
    element_label: 'Export list',
    element_name: ElementNames.exportPocsManualDates,
    element_type: ElementTypes.link,
  },
  [ElementNames.exportPocsWithoutDates]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.card,
    element_location_name: ElementLocationNames.automatedWithoutDates,
    element_label: 'Export list',
    element_name: ElementNames.exportPocsWithoutDates,
    element_type: ElementTypes.link,
  },
  [ElementNames.redirectUploadAndDownloads]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.ddcsEdit,
    element_label: 'Go to file management',
    element_name: ElementNames.redirectUploadAndDownloads,
    element_type: ElementTypes.link,
  },
  [ElementNames.ddcResendWindows]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.resendDeliveryWindows,
    element_label: 'Resend window',
    element_name: ElementNames.ddcResendWindows,
    element_type: ElementTypes.button,
  },
  // ddc edit tab
  [ElementNames.ddcEditDeliveryWindowTab]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.ddcsEdit,
    element_label: 'Delivery window',
    element_name: ElementNames.ddcEditDeliveryWindowTab,
    element_type: ElementTypes.tab,
  },
  [ElementNames.ddcEditOperationTab]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.ddcsEdit,
    element_label: 'DC Operation',
    element_name: ElementNames.ddcEditOperationTab,
    element_type: ElementTypes.tab,
  },
  [ElementNames.ddcEditClickAndCollectTab]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.ddcsEdit,
    element_label: 'Click & collect',
    element_name: ElementNames.ddcEditClickAndCollectTab,
    element_type: ElementTypes.tab,
  },
  [ElementNames.ddcEditDetailsTab]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.ddcsEdit,
    element_label: 'Details',
    element_name: ElementNames.ddcEditDetailsTab,
    element_type: ElementTypes.tab,
  },
  [ElementNames.ddcEditDeliveryRangeTab]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.ddcsEdit,
    element_label: 'Delivery range',
    element_name: ElementNames.ddcEditDeliveryRangeTab,
    element_type: ElementTypes.tab,
  },
  // custom settings
  [ElementNames.defaultSettingsView]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.ddcsEdit,
    element_label: 'Default settings',
    element_name: ElementNames.defaultSettingsView,
    element_type: ElementTypes.radioButton,
  },
  [ElementNames.customSettingsView]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.ddcsEdit,
    element_label: 'Custom settings',
    element_name: ElementNames.customSettingsView,
    element_type: ElementTypes.radioButton,
  },
  [ElementNames.createCustomSetting]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.ddcsEdit,
    element_label: 'Create custom settings',
    element_name: ElementNames.createCustomSetting,
    element_type: ElementTypes.button,
  },
  [ElementNames.editCustomSetting]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.ddcsEdit,
    element_label: 'Pen edit custom settings',
    element_name: ElementNames.editCustomSetting,
    element_type: ElementTypes.button,
  },
  [ElementNames.trashCanCustomSetting]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.ddcsEdit,
    element_label: 'Trash delete custom settings',
    element_name: ElementNames.trashCanCustomSetting,
    element_type: ElementTypes.button,
  },
  [ElementNames.confirmDeleteCustomSettings]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.modal,
    element_location_name: ElementLocationNames.deleteCustomSettingsModal,
    element_label: 'Yes, delete',
    element_name: ElementNames.delete,
    element_type: ElementTypes.button,
  },
  [ElementNames.cancelDeleteCustomSettings]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.modal,
    element_location_name: ElementLocationNames.deleteCustomSettingsModal,
    element_label: 'No, cancel',
    element_name: ElementNames.cancel,
    element_type: ElementTypes.button,
  },
  [ElementNames.deleteCustomSettings]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.customSettings,
    element_label: 'Delete settings',
    element_name: ElementNames.deleteCustomSettings,
    element_type: ElementTypes.button,
  },
  [ElementNames.saveCustomSettings]: {
    is_core_event: true,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.customSettings,
    element_label: 'Save changes',
    element_name: ElementNames.saveCustomSettings,
    element_type: ElementTypes.button,
  },
  [ElementNames.confirmDeleteCustomSettingsInternal]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.modal,
    element_location_name: ElementLocationNames.deleteCustomSettingsModal,
    element_label: 'Yes, delete',
    element_name: ElementNames.delete,
    element_type: ElementTypes.button,
  },
  [ElementNames.cancelDeleteCustomSettingsInternal]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.modal,
    element_location_name: ElementLocationNames.deleteCustomSettingsModal,
    element_label: 'No, cancel',
    element_name: ElementNames.cancel,
    element_type: ElementTypes.button,
  },
  [ElementNames.deliveryWindowParametersCustomSettingsSwitch]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsWindowRange,
    element_label: 'Window Range Switch',
    element_name: ElementNames.deliveryWindowParametersCustomSettingsSwitch,
    element_type: ElementTypes.switch,
  },
  [ElementNames.holidayCustomSettingsSwitch]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsHolidayStrategy,
    element_label: 'Holiday Strategy Switch',
    element_name: ElementNames.holidayCustomSettingsSwitch,
    element_type: ElementTypes.switch,
  },
  [ElementNames.automaticRulesCustomSettingsSwitch]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'Automatic Rules Switch',
    element_name: ElementNames.automaticRulesCustomSettingsSwitch,
    element_type: ElementTypes.switch,
  },
  [ElementNames.expirationCustomSettingsSwitch]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationBehavior,
    element_label: 'Expiration Behavior Switch',
    element_name: ElementNames.expirationCustomSettingsSwitch,
    element_type: ElementTypes.switch,
  },
  [ElementNames.windowRangeQuantifier]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsWindowRange,
    element_label: 'Window Range Selector',
    element_name: ElementNames.windowRangeQuantifier,
    element_type: ElementTypes.quantifier,
  },
  [ElementNames.holidayBehavior]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsHolidayStrategy,
    element_label: 'Behavior',
    element_name: ElementNames.holidayBehavior,
    element_type: ElementTypes.selector,
  },
  [ElementNames.workingDaysunday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationBehavior,
    element_label: 'Sunday',
    element_name: ElementNames.workingDaysunday,
    element_type: ElementTypes.chip,
  },
  [ElementNames.workingDaymonday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationBehavior,
    element_label: 'Monday',
    element_name: ElementNames.workingDaymonday,
    element_type: ElementTypes.chip,
  },
  [ElementNames.workingDaytuesday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationBehavior,
    element_label: 'Tuesday',
    element_name: ElementNames.workingDaytuesday,
    element_type: ElementTypes.chip,
  },
  [ElementNames.workingDaywednesday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationBehavior,
    element_label: 'Wednesday',
    element_name: ElementNames.workingDaywednesday,
    element_type: ElementTypes.chip,
  },
  [ElementNames.workingDaythursday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationBehavior,
    element_label: 'Thursday',
    element_name: ElementNames.workingDaythursday,
    element_type: ElementTypes.chip,
  },
  [ElementNames.workingDayfriday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationBehavior,
    element_label: 'Friday',
    element_name: ElementNames.workingDayfriday,
    element_type: ElementTypes.chip,
  },
  [ElementNames.workingDaysaturday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationBehavior,
    element_label: 'Saturday',
    element_name: ElementNames.workingDaysaturday,
    element_type: ElementTypes.chip,
  },
  [ElementNames.calculationRulesunday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationDay,
    element_label: 'Calculation rule Sunday',
    element_name: ElementNames.calculationRulesunday,
    element_type: ElementTypes.selector,
  },
  [ElementNames.calculationRulemonday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationDay,
    element_label: 'Calculation rule Monday',
    element_name: ElementNames.calculationRulemonday,
    element_type: ElementTypes.selector,
  },
  [ElementNames.calculationRuletuesday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationDay,
    element_label: 'Calculation rule Tuesday',
    element_name: ElementNames.calculationRuletuesday,
    element_type: ElementTypes.selector,
  },
  [ElementNames.calculationRulewednesday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationDay,
    element_label: 'Calculation rule Wednesday',
    element_name: ElementNames.calculationRulewednesday,
    element_type: ElementTypes.selector,
  },
  [ElementNames.calculationRulethursday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationDay,
    element_label: 'Calculation rule Thursday',
    element_name: ElementNames.calculationRulethursday,
    element_type: ElementTypes.selector,
  },
  [ElementNames.calculationRulefriday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationDay,
    element_label: 'Calculation rule Friday',
    element_name: ElementNames.calculationRulefriday,
    element_type: ElementTypes.selector,
  },
  [ElementNames.calculationRulesaturday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationDay,
    element_label: 'Calculation rule Saturday',
    element_name: ElementNames.calculationRulesaturday,
    element_type: ElementTypes.selector,
  },
  [ElementNames.expirationTimesunday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationTime,
    element_label: 'Deadline Sunday',
    element_name: ElementNames.expirationTimesunday,
    element_type: ElementTypes.timePicker,
  },
  [ElementNames.expirationTimemonday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationTime,
    element_label: 'Deadline Monday',
    element_name: ElementNames.expirationTimemonday,
    element_type: ElementTypes.timePicker,
  },
  [ElementNames.expirationTimetuesday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationTime,
    element_label: 'Deadline Tuesday',
    element_name: ElementNames.expirationTimetuesday,
    element_type: ElementTypes.timePicker,
  },
  [ElementNames.expirationTimewednesday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationTime,
    element_label: 'Deadline Wednesday',
    element_name: ElementNames.expirationTimewednesday,
    element_type: ElementTypes.timePicker,
  },
  [ElementNames.expirationTimethursday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationTime,
    element_label: 'Deadline Thursday',
    element_name: ElementNames.expirationTimethursday,
    element_type: ElementTypes.timePicker,
  },
  [ElementNames.expirationTimefriday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationTime,
    element_label: 'Deadline Friday',
    element_name: ElementNames.expirationTimefriday,
    element_type: ElementTypes.timePicker,
  },
  [ElementNames.expirationTimesaturday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsExpirationTime,
    element_label: 'Deadline Saturday',
    element_name: ElementNames.expirationTimesaturday,
    element_type: ElementTypes.timePicker,
  },
  [ElementNames.automaticParametersdeliveryDaysAfterVisitDay]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'BDR visit days',
    element_name: ElementNames.automaticParametersdeliveryDaysAfterVisitDay,
    element_type: ElementTypes.radioButton,
  },
  [ElementNames.automaticParametersdistanceRange]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'Nearby POC',
    element_name: ElementNames.automaticParametersdistanceRange,
    element_type: ElementTypes.radioButton,
  },
  [ElementNames.automaticParametersdynamicDaysOfWeek]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'Current day',
    element_name: ElementNames.automaticParametersdynamicDaysOfWeek,
    element_type: ElementTypes.radioButton,
  },
  [ElementNames.automaticParametersdeliveryDaysOfWeek]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'Fixed days',
    element_name: ElementNames.automaticParametersdeliveryDaysOfWeek,
    element_type: ElementTypes.radioButton,
  },

  [ElementNames.automaticParametersClearRule]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'Clear rule',
    element_name: ElementNames.automaticParametersClearRule,
    element_type: ElementTypes.button,
  },
  [ElementNames.automaticParametersFrequencyOnlyForNewClients]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'Apply delivery frequency only for new clients',
    element_name: ElementNames.automaticParametersFrequencyOnlyForNewClients,
    element_type: ElementTypes.switch,
  },
  [ElementNames.automaticParametersApplyRule1Only]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'Apply if the rule 1 is unavailable',
    element_name: ElementNames.automaticParametersApplyRule1Only,
    element_type: ElementTypes.switch,
  },
  [ElementNames.automaticParametersNearbyPocQuantifier]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'kilometers',
    element_name: ElementNames.automaticParametersNearbyPocQuantifier,
    element_type: ElementTypes.quantifier,
  },
  [ElementNames.automaticParametersBdrVisit0]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'On the same day',
    element_name: ElementNames.automaticParametersBdrVisit0,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersBdrVisit1]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: '1 Day After',
    element_name: ElementNames.automaticParametersBdrVisit1,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersBdrVisit2]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: '2 Days After',
    element_name: ElementNames.automaticParametersBdrVisit2,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersBdrVisit3]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: '3 Days After',
    element_name: ElementNames.automaticParametersBdrVisit3,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersBdrVisit4]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: '4 Days After',
    element_name: ElementNames.automaticParametersBdrVisit4,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersBdrVisit5]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: '5 Days After',
    element_name: ElementNames.automaticParametersBdrVisit5,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersBdrVisit6]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: '6 Days After',
    element_name: ElementNames.automaticParametersBdrVisit6,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersFrequencyweekly]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'Every week',
    element_name: ElementNames.automaticParametersFrequencyweekly,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersFrequencybiweekly]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'Every two weeks',
    element_name: ElementNames.automaticParametersFrequencybiweekly,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersFrequencytriweekly]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'Every three weeks',
    element_name: ElementNames.automaticParametersFrequencytriweekly,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersFrequencymonthly]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'Every four weeks',
    element_name: ElementNames.automaticParametersFrequencymonthly,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersCurrentDay0]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'On the same day',
    element_name: ElementNames.automaticParametersCurrentDay0,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersCurrentDay1]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: '1 Day After',
    element_name: ElementNames.automaticParametersCurrentDay1,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersCurrentDay2]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: '2 Days After',
    element_name: ElementNames.automaticParametersCurrentDay2,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersCurrentDay3]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: '3 Days After',
    element_name: ElementNames.automaticParametersCurrentDay3,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersCurrentDay4]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: '4 Days After',
    element_name: ElementNames.automaticParametersCurrentDay4,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersCurrentDay5]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: '5 Days After',
    element_name: ElementNames.automaticParametersCurrentDay5,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersCurrentDay6]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: '6 Days After',
    element_name: ElementNames.automaticParametersCurrentDay6,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersFixedDaysunday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'Sunday',
    element_name: ElementNames.automaticParametersFixedDaysunday,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersFixedDaymonday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'Monday',
    element_name: ElementNames.automaticParametersFixedDaymonday,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersFixedDaytuesday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'Tuesday',
    element_name: ElementNames.automaticParametersFixedDaytuesday,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersFixedDaywednesday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'Wednesday',
    element_name: ElementNames.automaticParametersFixedDaywednesday,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersFixedDaythursday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'Thursday',
    element_name: ElementNames.automaticParametersFixedDaythursday,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersFixedDayfriday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'Friday',
    element_name: ElementNames.automaticParametersFixedDayfriday,
    element_type: ElementTypes.chip,
  },
  [ElementNames.automaticParametersFixedDaysaturday]: {
    is_core_event: false,
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
    element_location_type: ElementLocationTypes.section,
    element_location_name: ElementLocationNames.customSettingsAutomaticRules,
    element_label: 'Saturday',
    element_name: ElementNames.automaticParametersFixedDaysaturday,
    element_type: ElementTypes.chip,
  },
};
