import { DDCDeliveryRange } from './DDCDeliveryRange';
import PaginationInfo from './PaginationInfo';

export type DDCDaysOfWeek =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';

export type DDCCode = string;

export enum DeliveryWindowMethod {
  deliveryRange = 'DELIVERY_RANGE',
  deliveryWindow = 'DELIVERY_WINDOW',
}

export enum DDCType {
  dc = 'DC',
  virutalDc = 'VIRTUAL_DC',
  urbanDc = 'URBAN_DC',
  factory = 'FACTORY',
}

export interface DDCClickAndCollectDayOfWeek {
  dayOfWeek: DDCDaysOfWeek;
  startTime: string;
  endTime: string;
  active: boolean;
  expirationTime: string;
  fullOrderCapacityValue: number;
  fullOrderCapacityActive: boolean;
}

export interface DDCAddress {
  zipCode?: string;
  state?: string;
  city?: string;
  operationAddress?: string;
  latitude?: string;
  longitude?: string;
}

interface DDC {
  ddcCode: DDCCode;
  id: string;
  timeZoneId?: string;
  name: string | null;
  active: boolean;
  isActive: boolean;
  generateDeliveryWindow: boolean;
  cityCode?: number;
  windowRange?: number;
  deliveryDaysOfWeek?: DDCDaysOfWeek[];
  deliveryDaysAfterVisitDay?: number[];
  missingFieldsToGenerateDeliveryWindow?: string[];
  holidayDeliveryDay?: number;
  deliveryWorkingDays?: DDCDaysOfWeek[];
  clickAndCollectActive?: boolean;
  clickAndCollectFullOrderCapacityActive?: boolean;
  isGeneratingDeliveryWindow?: boolean;
  tradeName?: string;
  deliveryCenterId?: string;
  governmentId?: string;
  deliveryWindowMethod?: DeliveryWindowMethod;
  type?: DDCType;
  parentId?: string;
  deliveryRange?: DDCDeliveryRange;
  address?: DDCAddress;
}

export interface DropdownDDC {
  name: string | null;
  ddcCode: DDCCode;
  id: string;
  externalId?: string;
  type?: DDCType;
}

export interface DayOfWeekSettings {
  day: DDCDaysOfWeek;
  expirationTime: Date;
  expirationDay: number;
}

export interface CompleteDDC extends DDC {
  daysOfWeekSettings: DayOfWeekSettings[];
  clickAndCollectRange?: number;
  clickAndCollectActive?: boolean;
  clickAndCollectDaysOfWeek?: DDCClickAndCollectDayOfWeek[];
}

export interface CompleteDDCFromApiDaysOfWeekSettings {
  day: DDCDaysOfWeek;
  expirationAbsoluteTime: string;
  expirationDay: number;
}

export interface DDCAudience {
  id: string;
  audienceExternalId: string;
  audienceName: string;
  lastUpdateDate: string;
  ddcAudienceExpirationSettings?: CompleteDDCFromApiDaysOfWeekSettings[];
  deliveryDaysAfterVisitDay?: number[];
  deliveryDaysOfWeek?: DDCDaysOfWeek[];
  holidayDeliveryDay?: number;
  windowRange?: number;
  deliveryWorkingDays?: Array<DDCDaysOfWeek>;
  automaticParameters?: [DDCAutomaticParameter?, DDCAutomaticParameter?];
  active: boolean;
}

export enum DDCAutomaticParameterOperator {
  and = 'and',
  or = 'or',
}
export enum DDCAutomaticParameterDeliveryFrequency {
  weekly = 'weekly',
  biweekly = 'biweekly',
  triweekly = 'triweekly',
  monthly = 'monthly',
}

export interface DDCAutomaticParameter {
  executionOrder: 1 | 2;
  operator: DDCAutomaticParameterOperator;
  deliveryFrequency: DDCAutomaticParameterDeliveryFrequency;
  deliveryDaysOfWeek?: DDCDaysOfWeek[];
  deliveryDaysAfterVisitDay?: number[];
  distanceRange?: number;
  dynamicDaysOfWeek?: number[];
  applyDeliveryFrequencyOnlyForNewClients: boolean;
}

export interface CompleteDDCFromApi extends DDC {
  daysOfWeekSettings: CompleteDDCFromApiDaysOfWeekSettings[];
  clickAndCollectRange?: number;
  clickAndCollectActive?: boolean;
  clickAndCollectDaysOfWeek?: DDCClickAndCollectDayOfWeek[];
  ddcAudiences: DDCAudience[];
  automaticParameters?: [DDCAutomaticParameter?, DDCAutomaticParameter?];
}

export type DDCListFilterFields = 'ddcCode' | 'ddcName';

export const DEFAULT_DDC_LIST_FILTER: DDCListFilterFields = 'ddcCode';

export enum DDCsListStatusFilterOptions {
  all = 'all',
  active = 'active',
  inactive = 'inactive',
}

export interface DDCListFilter {
  search: string;
  pagination: PaginationInfo;
  selectedVendor: string;
  status: DDCsListStatusFilterOptions;
}
/**
 * Type to make every part of the DDCListFilter partial without overlapping array types
 */
export interface DDCListFilterUpdate extends Partial<Omit<DDCListFilter, 'pagination'>> {
  pagination?: Partial<DDCListFilter['pagination']>;
}

export default DDC;
