import {
  DDCAddress,
  DDCAudience,
  DDCAutomaticParameter,
  DDCClickAndCollectDayOfWeek,
  DDCCode,
  DDCDaysOfWeek,
  DDCType,
  DeliveryWindowMethod,
  DropdownDDC,
} from './DDC';
import { DDCDeliveryRange } from './DDCDeliveryRange';

export interface DDCsEditDataDaysOfWeek {
  day: DDCDaysOfWeek;
  expirationDay: number | string | null;
  expirationAbsoluteTime: string | null;
}

export type DDCDaysOfWeekData = Partial<Record<DDCDaysOfWeek, DDCsEditDataDaysOfWeek>>;
export type DDCsEditDataClickAndCollectDaysOfWeekData = Record<
  DDCDaysOfWeek,
  DDCClickAndCollectDayOfWeek
>;

export interface clickAndCollectParametersEditData {
  range: number;
  enabled: boolean;
  expirationTime: string;
}
export interface clickAndCollectConsumeEditData {
  fullOrderCapacityActive: boolean;
  fullOrderCapacityValue: number;
  daysOfWeek: DDCsEditDataClickAndCollectDaysOfWeekData;
}

export type clickAndCollectEditData = clickAndCollectParametersEditData &
  clickAndCollectConsumeEditData;

export enum AutomaticParametersField {
  deliveryDaysAfterVisitDay = `deliveryDaysAfterVisitDay`,
  distanceRange = `distanceRange`,
  dynamicDaysOfWeek = `dynamicDaysOfWeek`,
  deliveryDaysOfWeek = `deliveryDaysOfWeek`,
}
export interface AutomaticParametersEditData extends DDCAutomaticParameter {
  field?: AutomaticParametersField | '';
}

export interface DDCDeliveryRangeEditData extends Omit<DDCDeliveryRange, 'shippingDays'> {
  shippingDays: DDCDaysOfWeek[];
}

export interface DDCsEditData {
  name?: string | null;
  federatedUnit?: number;
  cityCode?: number;
  singleDDC: boolean;
  timeZoneId: string | null;
  generateDeliveryWindow: boolean | null;
  deliveryDaysAfterVisitDay?: number[];
  deliveryDaysOfWeek?: Array<DDCDaysOfWeek>;
  daysOfWeekSettings?: DDCDaysOfWeekData;
  holidayDeliveryDay?: number | null;
  fieldState: DDCsEditFieldState;
  windowRange?: number;
  clickAndCollectParameters?: clickAndCollectParametersEditData;
  clickAndCollectConsume?: clickAndCollectConsumeEditData;
  deliveryWorkingDays?: Array<DDCDaysOfWeek>;
  locationFieldsEnabled?: boolean;
  editSettings?: EditSettingsValues;
  selectedAudience?: string;
  selectedAudienceName?: string;
  automaticParameters?: [AutomaticParametersEditData, AutomaticParametersEditData];
  tradeName?: string;
  deliveryCenterId?: string;
  ddcCode: DDCCode;
  governmentId?: string;
  deliveryWindowMethod?: DeliveryWindowMethod;
  type?: DDCType;
  parentId?: string;
  parentDDC?: DropdownDDC;
  deliveryRange?: DDCDeliveryRangeEditData;
  address?: DDCAddress;
}

export enum EditSettingsValues {
  default = 'default',
  custom = 'custom',
}

export enum DDCAudienceFieldName {
  windowRange = 'windowRange',
  holidayStrategy = 'holidayStrategy',
  automaticRules = 'automaticRules',
  expirationBehavior = 'expirationBehavior',
}

export type DDCAudienceFieldsFromApi = keyof Omit<
  DDCAudience,
  'id' | 'audienceExternalId' | 'audienceName' | 'lastUpdateDate'
>;

export const DDCAudienceFieldGroups: Record<DDCAudienceFieldName, DDCAudienceFieldsFromApi[]> = {
  windowRange: ['windowRange'],
  automaticRules: ['deliveryDaysAfterVisitDay', 'deliveryDaysOfWeek', 'automaticParameters'],
  holidayStrategy: ['holidayDeliveryDay'],
  expirationBehavior: ['ddcAudienceExpirationSettings'],
};

export interface DDCAudienceEditData {
  id: string;
  audienceExternalId: string;
  audienceName: string;
  lastUpdateDate: string;
  fields: DDCAudienceFieldName[];
  active: boolean;
}

export interface DDCAudienceSubmitData
  extends Omit<
    DDCAudience,
    | 'id'
    | 'audienceName'
    | 'lastUpdateDate'
    | 'ddcAudienceExpirationSettings'
    | 'audienceExternalId'
  > {
  expirationSettings?: DDCsEditDataDaysOfWeek[];
  externalId: string;
}

export type DDCsEditDataFields =
  | keyof DDCsEditData
  | keyof DDCsEditDataClickAndCollect
  | DDCAudienceFieldsFromApi;

export interface DDCsEditDataClickAndCollect {
  clickAndCollectRange?: number;
  clickAndCollectActive?: boolean;
  clickAndCollectDaysOfWeek?: DDCClickAndCollectDayOfWeek[];
}
export interface DDCsEditSubmitData
  extends Omit<
      DDCsEditData,
      'daysOfWeekSettings' | 'clickAndCollect' | 'automaticParameters' | 'deliveryRange'
    >,
    DDCsEditDataClickAndCollect {
  daysOfWeekSettings?: DDCsEditDataDaysOfWeek[];
  automaticParameters?: [DDCAutomaticParameter?, DDCAutomaticParameter?];
  deliveryRange?: DDCDeliveryRange;
}
export type DDCsEditDataDaysOfWeekAll = Omit<DDCsEditDataDaysOfWeek, 'day'>;

export type ControlFields = 'fieldState' | 'singleDDC' | 'locationFieldsEnabled' | 'editSettings';

export type DDCsEditFieldStateFields =
  | 'deliveryWindowParameters'
  | 'holiday'
  | 'automaticRules'
  | 'integrationIds'
  | 'expiration'
  | 'clickAndCollect'
  | 'deliveryRangeParameters'
  | 'details';

export type CustomSettingsEditFieldStateFields =
  | 'deliveryWindowParameters'
  | 'holiday'
  | 'automaticRules'
  | 'expiration';

export type DDCsEditFieldState = Record<DDCsEditFieldStateFields, boolean>;

export const DDCsEditFieldStateGroups: Record<DDCsEditFieldStateFields, DDCsEditDataFields[]> = {
  deliveryWindowParameters: ['generateDeliveryWindow', 'windowRange'],
  holiday: ['holidayDeliveryDay'],
  automaticRules: ['deliveryDaysAfterVisitDay', 'deliveryDaysOfWeek', 'automaticParameters'],
  integrationIds: ['cityCode', 'federatedUnit'],
  expiration: ['deliveryWorkingDays', 'daysOfWeekSettings', 'ddcAudienceExpirationSettings'],
  clickAndCollect: ['clickAndCollectActive', 'clickAndCollectRange', 'clickAndCollectDaysOfWeek'],
  deliveryRangeParameters: ['deliveryRange'],
  details: [
    'tradeName',
    'name',
    'timeZoneId',
    'governmentId',
    'address',
    'type',
    'parentId',
    'deliveryCenterId',
  ],
};

export const FieldGroupsByDeliveryMethod: Record<DeliveryWindowMethod, DDCsEditFieldStateFields[]> =
  {
    [DeliveryWindowMethod.deliveryRange]: ['details', 'deliveryRangeParameters'],
    [DeliveryWindowMethod.deliveryWindow]: [
      'automaticRules',
      'clickAndCollect',
      'deliveryWindowParameters',
      'details',
      'expiration',
      'holiday',
      'integrationIds',
    ],
  };

export const DDCsEditClickAndCollectParametersFieldStateGroup = [
  'clickAndCollectRange',
  'clickAndCollectActive',
];

export const DDCsEditClickAndCollectDaysOfWeekFieldStateGroup = ['clickAndCollectDaysOfWeek'];

export enum DDCsEditExceptionErrors {
  CncActiveDaysRequired = 'CncActiveDaysRequired',
  InvalidDayOfWeekSequence = 'InvalidDayOfWeekSequence',
  InvalidExpirationTime = 'InvalidExpirationTime',
  InvalidFullOrderCapacityActive = 'InvalidFullOrderCapacityActive',
  InvalidFullOrderCapacityValue = 'InvalidFullOrderCapacityValue',
  ErrorGetDdcBeesInformation = 'ErrorGetDdcBeesInformation',
  OtherError = 'OtherError',
}
export interface DDCsEditResponseData {
  exceptionDdcs: DDCsEditExceptionErrors[];
  notFoundDdcs: string[];
  updatedDdcs: string[];
}

export interface DDCsEditFormStatus {
  originalData: DDCsEditData;
  formDifference: DDCsEditData;
  isCustomSettingsForm?: boolean;
}
