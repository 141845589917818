import { useAuthenticationService, useUserMetadata } from 'admin-portal-shared-services';
import { ROUTER_BASENAME } from 'config/constants';
import { featureToggleItems } from 'config/featureToggleStates';
import { VendorFull } from 'domains/Vendor';
import { useStore } from 'effector-react';
import { useRouteScopes } from 'hooks/useRouteScopes/useRouteScopes';
import ErrorNoVendorRoute from 'pages/ErrorNoVendor/ErrorNoVendorRoute';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { setGroups, setListVendors, setUserData } from 'stores/MetaData/MetaDataEvents';
import MetaDataStore from 'stores/MetaData/MetaDataStore';
import { CheckOnePermission } from 'utils/checkUserScope/checkUserScope';
import { featureToggle } from 'utils/featureToggle/featureToggle';

const PATHNAME = `${ROUTER_BASENAME}`;

interface Props {
  children: React.ReactNode;
  featureToggleItem?: featureToggleItems;
  ignoreFullAccess?: boolean;
}

function PrivateRoute({
  children,
  featureToggleItem = featureToggleItems.empty,
  ignoreFullAccess = false,
}: Props): JSX.Element {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const JWTDecoded = useAuthenticationService().parseJwt();

  const scopes = useRouteScopes();
  const hasPermission = CheckOnePermission(scopes, ignoreFullAccess);
  const featureToggleEnabled = featureToggle(featureToggleItem);

  const { selectedCountry } = useStore(MetaDataStore);
  const userMetaData = useUserMetadata();
  const userData = userMetaData.data;
  const supportedVendors = userMetaData.data?.vendors as unknown as VendorFull[];
  const groups = userMetaData.data?.authorization?.groups;
  const isLoadingMetadata = userMetaData.isLoading;
  const shouldCheckVendor = featureToggle(featureToggleItems.noVendorsErrorScreen);
  const hasSupportedVendors = supportedVendors?.some(
    (vendor) => vendor.country === selectedCountry
  );

  const loadUserMetadata = React.useCallback(() => {
    setGroups(groups);
    setListVendors(supportedVendors);
    setUserData({
      email: JWTDecoded?.email || null,
      country: JWTDecoded?.country || null,
      id: userData?.userId || null,
      preferredLanguage: userData?.profile?.preferredLanguage || null,
      affiliation: userData?.affiliation || null,
    });

    setIsLoading(false);
  }, [groups, supportedVendors, userData]);

  React.useEffect(() => {
    if (!isLoadingMetadata) {
      loadUserMetadata();
    }
  }, [isLoadingMetadata]);

  const userHasPermission = React.useMemo(() => {
    if (featureToggleItem) {
      return hasPermission && featureToggleEnabled;
    }
    return hasPermission;
  }, [hasPermission, featureToggleEnabled]);

  const whatToRender = React.useMemo(() => {
    if (isLoading) {
      return <></>;
    }

    if (shouldCheckVendor && !hasSupportedVendors) {
      return <ErrorNoVendorRoute />;
    }

    if (!userHasPermission) {
      return <Redirect to={{ pathname: PATHNAME }} />;
    }

    return children;
  }, [shouldCheckVendor, hasSupportedVendors, isLoading, userHasPermission, children]);

  return <>{whatToRender}</>;
}

export default PrivateRoute;
