import { AutoblockSituationFilterOptions } from 'domains/AutoblockProgress';
import { ClientDayOfWeekType, ClientDaysOfWeekOrigins } from 'domains/Client';
import { ClientFileType } from 'domains/ClientFile';
import { DDCAutomaticParameterDeliveryFrequency, DDCType } from 'domains/DDC';
import {
  AddAutoblockErrorCodes,
  AutoblockChannels,
  DdcGroup,
  StrategyType,
} from 'domains/DDCAutoblockAdd';
import {
  AutomaticParametersField,
  DDCAudienceFieldName,
  DDCsEditExceptionErrors,
} from 'domains/DDCEditData';
import { DeliveryWindowOrigin, DeliveryWindowsModalError } from 'domains/DeliveryWindow';
import { FeatureActivationItems } from 'domains/FeatureActivation';
import { RuleDownloadFileSubType } from 'domains/RuleFile';
import { TranslationMatrix } from 'types/i18n.type';

const br: TranslationMatrix = {
  TEST: {
    TEST_TRANSLATE: 'Translate {value}',
    TEST_LOREM: 'Lorem',
    TEST_IPSUM: 'Ipsum',
    TEST_DOLOR: 'Dolor',
  },
  DayOfWeek: {
    monday: 'Segunda-feira',
    tuesday: 'Terça-feira',
    wednesday: 'Quarta-feira',
    thursday: 'Quinta-feira',
    friday: 'Sexta-feira',
    saturday: 'Sábado',
    sunday: 'Domingo',
  },
  DayOfWeekShort: {
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    sunday: 'Dom',
  },
  DayOfWeekRecurrency: {
    monday: 'Toda Segunda-feira',
    tuesday: 'Toda Terça-feira',
    wednesday: 'Toda Quarta-feira',
    thursday: 'Toda Quinta-feira',
    friday: 'Toda Sexta-feira',
    saturday: 'Todo Sábado',
    sunday: 'Todo Domingo',
  },
  DayOfWeekPreposition: {
    monday: 'na Segunda-feira',
    tuesday: 'na Terça-feira',
    wednesday: 'na Quarta-feira',
    thursday: 'na Quinta-feira',
    friday: 'na Sexta-feira',
    saturday: 'no Sábado',
    sunday: 'no Domingo',
  },
  General: {
    AND: 'e',
    CANCEL: 'Cancelar',
    CLEAR: 'Limpar',
    GO_BACK: 'Voltar',
    YES: 'Sim',
    NO: 'Não',
    OF: 'de',
    NA: 'N/A',
    EMPTY: 'Nenhum',
    ENABLED: 'Ativado',
    DISABLED: 'Desativado',
    DEFAULT_AUTOCOMPLETE_PLACEHOLDER: 'Selecione uma opção',
    DEFAULT_AUTOCOMPLETE_PLACEHOLDER_MULTIPLE: 'Selecione as opções',
    DEFAULT_EMPTY_LIST: 'Nenhum item para exibir',
    OPTIONAL: 'Opcional',
    UNAVAILABLE: 'Indisponível',
    ACTIVE: 'Ativo',
    INACTIVE: 'Inativo',
  },
  Clipboard: {
    COPY_TEXT_SUCCESS: 'Texto copiado para a área de transferência',
  },
  VendorSelect: {
    NO_VENDORS: 'Nenhum vendor setado',
  },
  AppBar: {
    MENU_DDCS_LIST: 'Gerenciar CDDs',
    MENU_DELIVERY_WINDOW: 'Janelas de Entrega',
    MENU_IMPORTS_AND_EXPORTS: 'Importações e Exportações',
    MENU_AUTOBLOCK: 'Parâmetros de disponibilidade de capacidade de entrega',
    MENU_AUDIENCES: 'Audiências',
    MENU_SELLER: 'Configurações do seller',
  },
  DataTable: {
    FOOTER_ROW_SELECTED_ONE: '{value} item selecionado',
    FOOTER_ROW_SELECTED_MANY: '{value} itens selecionados',
    PAGINATION_OF: 'de',
    NO_ROWS: 'Nenhum item para exibir',
    ONE_ROW_SELECTED: 'selecionado',
    MULTIPLE_ROWS_SELECTED: 'selecionados',
    DESELECT_ROWS: 'desselecionar',
    LOADING_MESSAGE: 'Carregando itens...',
  },
  Pagination: {
    PAGE_SIZE_OPTION: 'Mostrar {value} itens por página',
    OF: 'de',
  },
  DropZone: {
    CONTAINER_TEXT: 'Arraste e solte um arquivo CSV para subir ou clique para procurar',
    FILE_LIMITE_EXCEED: 'Limite de arquivos ({filesLimit}) excedido. ',
    PREVIEW: 'Arquivos Enviados: ',
    FILE_WAS_REJECTED: 'Arquivo inválido: "{fileName}". ',
    FILE_TYPE_NOT_SUPPORTED: 'Tipo de arquivo não permitido. ',
    FILE_SIZE_ERROR: 'Tamanho do arquivo ({fileSize}) excedido. ',
  },
  HexaDocumentUploader: {
    DROPZONE_PLACEHOLDER: 'Solte aqui seu arquivo',
    DROPZONE_ERROR_EMPTY: 'Por favor, selecione um arquivo válido',
    DROPZONE_ERROR_VALID_FILE:
      'O arquivo deve ter o formato válido ({format}) e conter no máximo {size}',
    DROPZONE_BUTTON: 'Procurar',
  },
  DateRange: {
    START_DATE: 'Data inicial',
    END_DATE: 'Data final',
    DATE_FORMAT: 'dd/MM/yyyy',
  },
  TabNavigation: {
    DCCS_LIST: 'Gerenciamento de Parâmetros',
  },
  GenerateDeliveryWindowsButton: {
    BUTTON_TEXT: 'Reenviar janelas',
    MODAL_TITLE: 'Reenviar janelas de entrega?',
    MODAL_TEXT:
      'Esta ação sincronizará manualmente as janelas de entrega com o aplicativo BEES Customer.',
    CONFIRM_BUTTON: 'Sim, reenviar',
    CANCEL_BUTTON: 'Não, voltar',
    ERROR_MESSAGE: 'Erro ao solicitar a geração de janelas de entrega',
    SUCCESS_MESSAGE: 'O processo de geração de janelas de entrega solicitado está em andamento',
  },
  DDCsList: {
    TITLE: 'Gerenciar CDDs',
    BREADCRUMB: 'Gerenciar CDDs',
    DATAGRID_COLUMN_DDC_CODE: 'Código do CDD',
    DATAGRID_COLUMN_DDC_NAME: 'Nome do CDD',
    DATAGRID_COLUMN_DDC_STATUS: 'Status',
    DATAGRID_COLUMN_ACTIONS: 'Ações',
    DATAGRID_CELL_ACTIONS_EDIT_TOOLTIP: 'Visualizar/editar',
    DATAGRID_CELL_ACTIONS_HOLIDAY_TOOLTIP: 'Gerenciar Feriados',
    DATAGRID_CELL_ACTIONS_RULES_TOOLTIP: 'Gerenciar Regras de Inclusão/Exclusão',
    DATAGRID_CELL_ACTIONS_CNC_TOOLTIP: 'Ver consumo Click & Collect',
    UNABLE_TO_LOAD_LIST: 'Erro ao carregar a lista de CDDs',
    EDIT_SELECTION_BUTTON: 'Editar Seleção',
    HOLIDAY_SELECTION_BUTTON: 'Gerenciar Feriados',
    RULES_SELECTION_BUTTON: 'Gerenciar regras de Exclusão/Inclusão',
    SEARCH_LABEL: 'Nome ou Cód. do CDD',
    SEARCH_FILTER_LABEL: 'Filtro',
    FILTER_STATUS_ALL: 'Todos os status',
    FILTER_STATUS_ACTIVE: 'Status ativado',
    FILTER_STATUS_INACTIVE: 'Status desativado',
    AUDIENCES_ACTION_BUTTON: 'Ir para Audiências',
    STATUS_ENABLED_CNC: 'Habilitado para Click & Collect',
  },
  DDCsListClickAndCollectModal: {
    SUBTITLE: 'Click & Collect',
    DATAGRID_COLUMN_DATE: 'Data de coleta',
    DATAGRID_COLUMN_CAPACITY: 'Capacidade',
    DATAGRID_COLUMN_CONSUME: 'Utilização',
    DATEFORMAT: 'EEE, dd MMM, yyyy',
    DATETIMEFORMAT: 'EEE, dd MMM, yyyy, HH:mm',
    UNABLE_TO_LOAD_LIST: 'Erro ao carregar a lista de Click & Collect',
    BUTTON_TOOLTIP: 'Ver consumo Click & Collect',
    BUTTON_TOOLTIP_DISABLED: 'Estes parâmetros Click & Collect estão desabilitados: {fields}',
    BUTTON_TOOLTIP_FIELD_ACTIVE: '"Dia da Semana"',
    BUTTON_TOOLTIP_FIELD_FULL_ORDER_CAPACITY: '"Capacidade"',
  },
  DDCsListHolidayConfirmModal: {
    TITLE: 'Gerenciamento de Feriados',
    CONFIRM_BUTTON: 'Continuar',
    CANCEL_BUTTON: 'Cancelar',
    UPPER_TEXT:
      'Não é possível gerenciar os feriados das Unidades abaixo, pois não possuem a informação de Localidade:',
    LOWER_TEXT: 'Deseja continuar?',
  },
  DDCsEdit: {
    TITLE: 'Editar Dados',
    BREADCRUMB: 'Editar Parâmetros',
    SUBMIT_BUTTON: 'Confirmar Parâmetros',
    DDCS_LIST_TITLE: 'Unidades Selecionadas',
    DDCS_LIST_VIEW_MORE: 'Ver Mais',
    DDCS_LIST_HIDE: 'Esconder',
    VISIT_DAY: 'Dia da Visita',
    HOLIDAY_DATE: 'Data do Feriado',
    DELIVERY_DAY: 'Dia da Entrega',
    FORM_PARAMETERS_TITLE: 'Parâmetros Gerais',
    FORM_GENERATE_DELIVERY_WINDOW: 'Gerar Janelas de Entrega',
    FORM_TIMEZONE: 'Fuso Horário',
    FORM_DAYS_AFTER_VISIT_DAY: 'Regra automática - Dias Corridos',
    FORM_DAYS_OF_WEEK: 'Regra automática - Dias da Semana',
    FORM_HOLIDAY_DELIVERY_DAY: 'Dia de Entrega do Feriado',
    FORM_HOLIDAY_DELIVERY_DAY_ZERO: 'Não substituir feriado',
    FORM_WINDOW_RANGE: 'Número de dias para gerar janelas de entrega',
    FORM_EXPIRATION_DELIVERY_DAY_IS: 'Quando a entrega é',
    FORM_EXPIRATION_EXPIRATION_DAY_IS: 'Quando a expiração é',
    FORM_EXPIRATION_DAY: 'Dia de expiração',
    FORM_EXPIRATION_TIME: 'Horário de expiração (local)',
    FORM_EXPIRATION_USE_FOR_ALL: 'Aplicar para todos',
    FORM_EXPIRATION_LABEL: 'Data e Horário de Expiração:',
    FORM_WORKING_DAYS_LABEL: 'Dias trabalhados',
    FORM_WORKING_DAYS_HINT:
      'Data de expiração considerará estes dias como dias válidos de expiração',
    FORM_CLICK_AND_COLLECT_PARAMETERS_LABEL: 'Click & Collect - Parâmetros básicos',
    FORM_CLICK_AND_COLLECT_CONSUME_LABEL: 'Click & Collect - Dias da Semana',
    FORM_CLICK_AND_COLLECT_ENABLED_LABEL: 'Click & Collect habilitado',
    FORM_CLICK_AND_COLLECT_RANGE_LABEL: 'Limite de dias',
    FORM_CLICK_AND_COLLECT_EXPIRATION_LABEL: 'Hora de expiração (D-1)',
    FORM_CLICK_AND_COLLECT_DAY_ENABLED_LABEL: 'Dia habilitado',
    FORM_CLICK_AND_COLLECT_DAY_START_LABEL: 'Início',
    FORM_CLICK_AND_COLLECT_DAY_END_LABEL: 'Fim',
    FORM_CLICK_AND_COLLECT_TIME_ERROR: 'Hora de início deve ser menor que hora de fim',
    FORM_CLICK_AND_COLLECT_DISABLE_ERROR:
      'Uma vez habilitado, o Click and Collect deve permanecer habilitado para ao menos um dia da semana.\nPara desabilitar completamente, será necessário alterar a configuração de Click and Collect dos PDVs desta Unidade.',
    FORM_CLICK_AND_COLLECT_FULLORDERCAPACITY_ENABLED_LABEL: 'Gerenciar Capacidade Click & Collect',
    FORM_CLICK_AND_COLLECT_FULLORDERCAPACITY_VALUE_LABEL: 'Capacidade Click & Collect',
    UNABLE_TO_EDIT_CDDS: 'Erro ao editar as Unidades',
    UNABLE_TO_GET_DDC_INFO: 'Erro ao buscar os dados da Unidade',
    SAVING_SUCCESS: 'Parâmetros salvos com sucesso',
    SAVING_ERROR: 'Uma ou mais unidades não puderam ser salvas.',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.CncActiveDaysRequired.toUpperCase()}`]:
      'Necessário ter ao menos um dia de Click and Collect ativo.',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.InvalidDayOfWeekSequence.toUpperCase()}`]:
      'Dias da semana inválidos para expiração.',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.InvalidExpirationTime.toUpperCase()}`]:
      'Horário de expiração inválido.',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.InvalidFullOrderCapacityActive.toUpperCase()}`]:
      'Dados de Capacidade Click & Collect inválidos.',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.InvalidFullOrderCapacityValue.toUpperCase()}`]:
      'Dados de Capacidade Click & Collect inválidos.',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.ErrorGetDdcBeesInformation.toUpperCase()}`]:
      'Erro ao retornar informações da unidade (BEES).',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.OtherError.toUpperCase()}`]:
      'por favor, tente mais tarde.',
    FORM_GENERATE_DELIVERY_WINDOW_NULL: 'Não alterar',
    FORM_GENERATE_DELIVERY_WINDOW_ENABLED: 'Ativado',
    FORM_GENERATE_DELIVERY_WINDOW_DISABLED: 'Desativado',
    WORKING_DAYS_ALERT:
      'As janelas de entrega e dias de expiração de janela de entrega somente ocorrem nos dias que estão selecionados como trabalhados. Alterar esse campo irá recalcular as janelas de entrega de todo o centro de distribuição',
    TOGGLE_BUTTON: 'Editar campo',
    TOGGLE_TUTORIAL_1:
      'Habilite ou desabilite os campos que deseja ou não alterar clicando nos botões “',
    TOGGLE_TUTORIAL_2:
      '”.\nCampos habilitados, mas sem valor serão salvos como vazios. Campos desabilitados não serão alterados.',
  },
  DDCsEditV2: {
    TITLE: 'Editar configurações',
    BREADCRUMB: 'Editar configurações',
    DELIVERY_DAY: 'Dia da entrega',
    SAVE_BUTTON: 'Salvar alterações',
    SELECTED_DDCS_TITLE: 'DDCs Selecionados',
    SELECTED_DDCS_TEXT:
      'Configurações salvas serão usadas para gerar janelas de entrega para os CDDs selecionados.',
    BULK_WARNING:
      'Ao editar vários CDDs, os campos editados são aplicados a todos os CDDs selecionados.',
    BULK_BUTTON_DISABLED: 'Editar seção',
    BULK_BUTTON_ENABLED: 'Cancelar edição',
    TAB_DELIVERY_WINDOWS: 'Janelas de entrega',
    TAB_DDC_OPERATION: 'Operação do DDC',
    TAB_CLICK_AND_COLLECT: 'Click & Collect',
    PARAMETERS_TITLE: 'Parâmetros',
    FIELD_GENERATE_DELIVERY_WINDOWS_LABEL: 'Gerar janelas de entrega',
    FIELD_WINDOW_RANGE_LABEL: 'Faixa de janelas',
    FIELD_WINDOW_RANGE_HINT:
      'Os pedidos podem ser entregues entre de 1 a 60 dias após a data de realização do pedido.',
    HOLIDAY_TITLE: 'Estratégia de feriados',
    HOLIDAY_TEXT: 'Defina o comportamento quando uma janela de entrega cair em um feriado.',
    BUTTON_MANAGE_HOLIDAYS: 'Gerenciar feriados',
    FIELD_HOLIDAY_DELIVERY_DAY: 'Comportamento',
    FIELD_HOLIDAY_DELIVERY_DAY_ZERO: 'Não substituir feriados',
    HOLIDAY_DATE: 'Data do feriado',
    FIELD_HOLIDAY_DELIVERY_DAY_TEXT_ZERO:
      'Quando a janela de entrega cai em um feriado, a data de entrega do pedido permanece a mesma.',
    FIELD_HOLIDAY_DELIVERY_DAY_TEXT:
      'Quando a janela de entrega cair em um feriado, a data de entrega do pedido deverá ser atualizada para ',
    FIELD_HOLIDAY_DELIVERY_DAY_TEXT_POSITIVE: ' dia(s) após o feriado.',
    FIELD_HOLIDAY_DELIVERY_DAY_TEXT_NEGATIVE: ' dia(s) antes do feriado.',
    AUTOMATIC_RULES_TITLE: 'Regras automáticas',
    AUTOMATIC_RULES_TEXT:
      'Estas regras se aplicam apenas quando a opção Padrão do CDD estiver selecionada na edição de clientes',
    FIELD_AUTOMATIC_RULE_VISIT_DAY_LABEL: 'Por dia da visita',
    FIELD_AUTOMATIC_RULE_VISIT_DAY_HINT:
      'Criar janelas de entrega em determinados dias após uma visita',
    FIELD_AUTOMATIC_RULE_VISIT_DAY_OPTION_ZERO: 'No mesmo dia',
    FIELD_AUTOMATIC_RULE_VISIT_DAY_OPTION_SINGULAR: '1 dia depois',
    FIELD_AUTOMATIC_RULE_VISIT_DAY_OPTION_PLURAL: '{value} dias depois',
    FIELD_AUTOMATIC_RULE_DAY_OF_WEEK_LABEL: 'Dias fixos',
    FIELD_AUTOMATIC_RULE_DAY_OF_WEEK_HINT:
      'Criar janelas de entrega recorrentes dentro da faixa de janelas para os dias da semana específicos.',
    TIMEZONE_TITLE: 'Parâmetros',
    FIELD_TIMEZONE_LABEL: 'Fuso horário',
    FIELD_TIMEZONE_HINT: 'Selecione o fuso horário para as operações do CDD.',
    EXPIRATION_TITLE: 'Comportamento de expiração padrão',
    EXPIRATION_TEXT:
      'Escolha o prazo padrão do pedido com base na entrega e nos dias trabalhados. Essas regras podem ser substituídas por exceções configuradas na plataforma.',
    FIELD_WORKING_DAYS_LABEL: 'Dias trabalhados',
    FIELD_WORKING_DAYS_HINT: 'Selecione quais dias da semana o CDD irá trabalhar',
    EXPIRATION_DAYS_TITLE: 'Dias de expiração',
    EXPIRATION_DAYS_TEXT:
      'Selecione a regra de cálculo para encontrar o dia de vencimento correspondente a cada dia de entrega.',
    FIELD_DELIVERY_DAY_LABEL: 'Dia de entrega',
    FIELD_EXPIRATION_DAY_LABEL: 'Regra de cálculo',
    FIELD_CALCULATED_EXPIRATION_DAY_LABEL: 'Dia de expiração',
    EXPIRATION_TIMES_TITLE: 'Prazos para realização de pedidos',
    EXPIRATION_TIMES_TEXT:
      'Defina o prazo para realização de um pedido correspondente a cada dia de vencimento.',
    FIELD_EXPIRATION_TIME_LABEL: 'Prazo final (Horário do CDD)',
    CNC_PARAMETERS_TITLE: 'Parâmetros',
    CNC_EXPIRATION_TIME_LABEL: 'Limite para pedidos (D-1)',
    CNC_EXPIRATION_TIME_HINT: 'Limite de horário para realizar pedidos no dia anterior.',
    FIELD_EXPIRATION_ALL_BUTTON: 'Aplicar para todos os dias',
    CNC_RANGE_LABEL: 'Faixa de janelas',
    CNC_RANGE_HINT: 'Configure a faixa de janelas do Click & Collect de 1 a 60 dias.',
    CNC_FULL_ORDER_CAPACITY_ACTIVE_LABEL: 'Gerenciar capacidade',
    CNC_FULL_ORDER_CAPACITY_ACTIVE_HINT: 'Habilitar o gerenciamento de capacidade Click & Collect',
    CNC_FULL_ORDER_CAPACITY_VALUE_LABEL: 'Capacidade de pedidos',
    CNC_FULL_ORDER_CAPACITY_VALUE_HINT: 'Limite a quantidade diária de pedidos permitidos.',
    CNC_DAYS_OF_WEEK_TITLE: 'Configuração do intervalo de tempo',
    CNC_DAYS_OF_WEEK_TEXT:
      'Defina o horário de início e término do Click & Collect para cada dia da semana.',
    CNC_START_TIME_LABEL: 'Início',
    CNC_END_TIME_LABEL: 'Fim',
    CNC_DAY_OF_WEEK_DISABLED: 'Coleta desabilitada',
    CLICK_AND_COLLECT_CAPACITY_TITLE: 'Capacidade atual',
    IDENTIFIERS_TITLE: 'IDs de Integração',
    FIELD_DDC_NAME_LABEL: 'Nome legal',
    FIELD_DDC_NAME_PLACEHOLDER: 'Informe o nome do CDD',
    FIELD_BEES_ID_LABEL: 'BEES ID',
    FIELD_BEES_ID_HINT: 'Este ID identifica o CDD dentro do ecossistema BEES.',
    FIELD_BEES_ID_PLACEHOLDER: 'Informe o BEES ID',
    FIELD_CITY_CODE_LABEL: 'Código do município',
    FIELD_CITY_CODE_HINT:
      'O mesmo que o ID do município, é usado para integração com feriados por algumas zonas.',
    FIELD_CITY_CODE_PLACEHOLDER: 'Informe o código do município',
    FIELD_FEDERATED_UNIT_LABEL: 'Estado',
    FIELD_FEDERATED_UNIT_HINT:
      'O mesmo que o ID do Estado, é usado para integração com feriados por algumas zonas.',
    FIELD_FEDERATED_UNIT_PLACEHOLDER: 'Informe o Estado',
    EDIT_SETTINGS_LABEL: 'Tipo de configurações',
    EIDT_SETTINGS_TOOLTIP:
      'As configurações padrão são aplicadas aos POCs dentro dos CDDs selecionados. As configurações personalizadas as substituem para públicos e CDDs específicos.',
    EDIT_SETTINGS_DEFAULT: 'Configurações padrão',
    EDIT_SETTINGS_CUSTOM: 'Configurações personalizadas',
    CUSTOM_SETTINGS_TITLE: 'Configuração personalizada',
    CUSTOM_SETTINGS_TEXT:
      'A configuração personalizada sobrescreve a configuração padrão para os POCs dentro das audiências e CDDs.',
    CUSTOM_SETTINGS_NAME_TOOLTIP:
      'O nome da configuração é o mesmo que o nome da audiência selecionada durante a criação da configuração.',
    CUSTOM_SETTINGS_CREATE_BUTTON: 'Criar configuração personalizada',
    CUSTOM_SETTINGS_TABLE_EMPTY: 'Nenhuma configuração personalizada criada ainda.',
    CUSTOM_SETTINGS_TABLE_NAME_COLUMN: 'Nome da configuração',
    CUSTOM_SETTINGS_TABLE_NAME_NO_CLIENT_TOOLTIP:
      'Não há POCs neste DDC que pertençam a essa audiência.',
    CUSTOM_SETTINGS_TABLE_NAME_COLUMN_TOOLTIP:
      'O nome da configuração é o mesmo que o nome da audiência selecionada durante a criação da configuração.',
    CUSTOM_SETTINGS_TABLE_FIELDS_COLUMN: 'Opções de configuração',
    [`CUSTOM_TABLE_FIELD_${DDCAudienceFieldName.expirationBehavior}`]: 'Comportamento de expiração',
    [`CUSTOM_TABLE_FIELD_${DDCAudienceFieldName.automaticRules}`]: 'Regras automáticas',
    [`CUSTOM_TABLE_FIELD_${DDCAudienceFieldName.holidayStrategy}`]: 'Estratégia de feriados',
    [`CUSTOM_TABLE_FIELD_${DDCAudienceFieldName.windowRange}`]: 'Faixa de janelas',
    CUSTOM_SETTINGS_TABLE_CREATION_COLUMN: 'Salvo em',
    CUSTOM_SETTINGS_TABLE_CREATION_FORMAT: 'dd MMM, yyyy, HH:mm OOOO',
    CUSTOM_SETTINGS_TABLE_ACTION_COLUMN: 'Ações',
    CUSTOM_SETTINGS_DELETE_MODAL_TITLE: 'Excluir configuração personalizada?',
    CUSTOM_SETTINGS_DELETE_MODAL_TEXT:
      'Se você excluir estas configurações, você a perderá para todos os POCs dentro das audiências e CDDs.',
    CUSTOM_SETTINGS_DELETE_MODAL_DDC_LABEL: 'CDDs',
    CUSTOM_SETTINGS_DELETE_MODAL_SETTINGS_LABEL: 'Nomes das configurações',
    CUSTOM_SETTINGS_DELETE_MODAL_CONFIRM_BUTTON: 'Sim, excluir',
    CUSTOM_SETTINGS_DELETE_MODAL_CANCEL_BUTTON: 'Não, cancelar',
    TAB_DETAILS: 'Detalhes',
    DETAILS_TITLE: 'Informações do centro de distribuição',
    FIELD_TRADE_NAME_LABEL: 'Nome comercial',
    FIELD_TRADE_NAME_PLACEHOLDER: 'Digite o nome comercial',
    FIELD_DC_ID_LABEL: 'ID do CDD',
    FIELD_DC_ID_PLACEHOLDER: 'Digite o ID do CDD',
    FIELD_COMPANY_DC_ID_LABEL: 'ID da empresa do CDD',
    FIELD_COMPANY_DC_ID_PLACEHOLDER: 'Digite o ID da empresa do CDD',
    FIELD_GOVERNMENT_ID_LABEL: 'ID governamental',
    FIELD_GOVERNMENT_ID_PLACEHOLDER: 'Digite o ID governamental',
    FIELD_ZIPCODE_LABEL: 'CEP',
    FIELD_ZIPCODE_PLACEHOLDER: 'Digite o CEP',
    FIELD_STATE_LABEL: 'Estado',
    FIELD_STATE_PLACEHOLDER: 'Digite o estado',
    FIELD_CITY_LABEL: 'Cidade',
    FIELD_CITY_PLACEHOLDER: 'Digite a cidade',
    FIELD_OPERATION_ADDRESS_LABEL: 'Endereço de operação',
    FIELD_OPERATION_ADDRESS_PLACEHOLDER: 'Digite o endereço de operação',
    FIELD_TYPE_LABEL: 'Tipo de centro de distribuição',
    FIELD_PARENT_ID_LABEL: 'ID do pai',
    FIELD_LAT_LABEL: 'Latitude',
    FIELD_LAT_PLACEHOLDER: 'Digite a latitude',
    FIELD_LONG_LABEL: 'Longitude',
    FIELD_LONG_PLACEHOLDER: 'Digite a longitude',
    TAB_DELIVERY_RANGE: 'Intervalo de entrega',
    DELIVERY_RANGE_TITLE: 'Parâmetros',
    FIELD_DELIVERY_RANGE_LABEL: 'Intervalo de entrega',
    FIELD_DELIVERY_RANGE_HINT:
      'Número de dias necessários desde a análise do pedido até a entrega.\nInforme os valores mínimos e máximos.',
    FIELD_RANGE_FROM_LABEL: 'De',
    FIELD_RANGE_TO_LABEL: 'até',
    FIELD_RANGE_TEXT: 'dias úteis até a entrega.',
    FIELD_DELIVERY_DAYS_LABEL: 'Dias de entrega',
    FIELD_DELIVERY_DAYS_HINT:
      'Selecione os dias da semana disponíveis para entrega para que o POC tenha melhor previsibilidade.',
  },
  DDCType: {
    [DDCType.dc]: 'Centro de distribuição (CDD)',
    [DDCType.factory]: 'Fábrica',
    [DDCType.urbanDc]: 'Centro de Distribuição Urbano (UDC)',
    [DDCType.virutalDc]: 'Centro de Distribuição Virtual (VDC)',
  },
  DDCsEditV2Statistics: {
    AUTOMATED_DATE_TITLE: 'Data Automatizada',
    AUTOMATED_DATE_TOOLTIP: 'POCs com datas de entrega definidas através de regras automatizadas.',
    MANUAL_DATE_TITLE: 'Data Manual',
    MANUAL_DATE_TOOLTIP: 'POCs com datas de entrega definidas através de uploads de CSV.',
    NO_DATE_TITLE: 'Nenhuma data definida',
    NO_DATE_TOOLTIP: 'POCs sem datas de entrega definidas.',
    EXPORT_BUTTON: 'Exportar lista',
    POC_SINGULAR: 'POC',
    POC_PLURAL: 'POCs',
    TOAST_LOADING_ERROR: 'Erro ao recuperar dados dos POCs',
    ALERT_FILE_EXPORTED_TEXT:
      'A lista está sendo processada e estará disponível na página de Gerenciamento de Arquivos.',
    ALERT_FILE_EXPORTED_ACTION: 'Ir para o gerenciamento de arquivos',
  },
  DDCsEditV2AutomaticParameters: {
    RULE_1_TITLE: 'Regra 1',
    RULE_1_TEXT: 'Defina os critérios padrão para gerar datas de entrega.',
    RULE_2_TITLE: 'Regra 2',
    RULE_2_TEXT: 'Defina o que acontece quando o segundo critério se aplica.',
    RULE_TYPE_LABEL: 'Regra baseada em',
    [`RULE_TYPE_${AutomaticParametersField.deliveryDaysAfterVisitDay}`]: 'Dias de visita BDR',
    [`RULE_TYPE_${AutomaticParametersField.distanceRange}`]: 'POC próximo',
    [`RULE_TYPE_${AutomaticParametersField.dynamicDaysOfWeek}`]: 'Dia atual',
    [`RULE_TYPE_${AutomaticParametersField.deliveryDaysOfWeek}`]: 'Dias fixos',
    FREQUENCY_LABEL: 'Frequência',
    [`FREQUENCY_${DDCAutomaticParameterDeliveryFrequency.weekly}`]: 'Toda semana',
    [`FREQUENCY_${DDCAutomaticParameterDeliveryFrequency.biweekly}`]: 'A cada duas semanas',
    [`FREQUENCY_${DDCAutomaticParameterDeliveryFrequency.triweekly}`]: 'A cada três semanas',
    [`FREQUENCY_${DDCAutomaticParameterDeliveryFrequency.monthly}`]: 'A cada quatro semanas',
    NEARBY_POC_LABEL: 'Distância do cliente',
    NEARBY_POC_HINT: 'quilômetros (até 30)',
    FREQUENCY_TOGGLE_LABEL: 'Aplicar frequência de entrega apenas para novos clientes',
    FREQUENCY_TOGGLE_CONFIRM_TITLE: 'Aplicar frequência para todos os clientes?',
    FREQUENCY_TOGGLE_CONFIRM_TEXT:
      'Ao desmarcar essa opção todos os clientes que são padrão DDC terão a frequência de entrega alterada.',
    FREQUENCY_TOGGLE_CONFIRM_BUTTON_CONFIRM: 'Sim, aplicar para todos',
    FREQUENCY_TOGGLE_CONFIRM_BUTTON_CANCEL: 'Não, voltar',
    DYNAMIC_DAYS_LABEL: 'Dias após o dia atual',
    SECOND_RULE_TOGGLE_LABEL: 'Aplicar se a regra 1 não estiver disponível',
    SECOND_RULE_TOGGLE_TOOLTIP:
      'A Regra 2 será aplicada se a Regra 1 não estiver disponível. Sem seleção, ambas serão usadas.',
    SECOND_RULE_TOOTLIP_MODAL_TITLE: 'Usar a regra 1 e a regra 2?',
    SECOND_RULE_TOOTLIP_MODAL_TEXT:
      'Ambas as regras serão usadas para gerar automaticamente as datas de entrega.',
    SECOND_RULE_TOOTLIP_MODAL_CONFIRM: 'Usar ambas',
    CLEAR_BUTTON: 'Limpar regra',
  },
  CustomSettingsEdit: {
    TITLE: 'Configuração personalizada',
    BREADCRUMB: 'Configuração personalizada',
    SELECTED_DDCS_TEXT:
      'As configurações salvas serão usadas para criar configurações personalizadas para os CDDs selecionados.',
    AUDIENCE_TITLE: 'Audiência',
    AUDIENCE_TEXT: 'Selecione o audiência de POCs para o qual essas configurações serão aplicadas.',
    AUDIENCE_HELP_MODAL_BUTTON: 'Como criar uma audiência?',
    AUDIENCE_LAST_MODIFIED_TEXT: 'Esta audiência foi modificada pela última vez em',
    AUDIENCE_LAST_MODIFIED_FORMAT: 'EEE dd MMM yyyy',
    AUDIENCE_CLIENT_DISCLAIMER:
      'Este valor representa a quantidade total de POCs da audiência. Ao salvar nós filtratemos apenas as POCs que pertencem ao DDC selecionado para aplicar as configurações custom.',
    SETTINGS_OPTIONS_TITLE: 'Opções de configuração',
    SETTINGS_OPTIONS_TEXT:
      'Defina regras automáticas para janelas de entrega, comportamento de expiração de pedidos e mais.',
    WINDOW_RANGE_TITLE: 'Faixa de janelas',
    WINDOW_RANGE_TEXT:
      'Os pedidos podem ser entregues dentro de 1 a 60 dias após a data de realização do pedido.',
    HOLIDAY_TITLE: 'Estratégia de feriados',
    HOLIDAY_TEXT: 'Defina o comportamento quando uma janela de entrega cair em um feriado.',
    AUTOMATIC_RULES_TITLE: 'Regras automáticas',
    AUTOMATIC_RULES_TEXT:
      'Essas regras só se aplicam quando a opção padrão do DC é selecionada na página de Editar clientes.',
    EXPIRATION_TITLE: 'Comportamento de expiração',
    EXPIRATION_TEXT:
      'Escolha o prazo do pedido com base nos dias de entrega e dias úteis. Essas regras podem ser substituídas por exceções configuradas na plataforma.',
    SAVING_SUCCES_TOAST: 'Configurações personalizadas salvas com sucesso.',
    SAVING_ERROR_TOAST: 'Erro ao salvar as configurações personalizadas',
    EDIT_SELECTED_DC_LABEL: 'CDD selecionado',
    EDIT_CUSTOM_SETTINGS_NAME: 'Nome da configuração',
    EDIT_CUSTOM_SETTINGS_NAME_TOOLTIP:
      'O nome da configuração é o mesmo que o nome da audiência selecionada durante a criação da configuração.',
    DELETE_SETTINGS_BUTTON: 'Deletar configuração',
    DELETE_SETTINGS_TOAST_SUCCESS: 'Configurações personalizadas excluídas com sucesso.',
    DELETE_SETTINGS_TOAST_ERROR: 'Erro ao remover configurações personalizadas',
  },
  DDCsEditModalConfirmV2: {
    MODAL_TITLE: 'Salvar alterações?',
    MODAL_TEXT: 'As configurações abaixo serão aplicadas para todos os CDDs selecionados.',
    BUTTON_CANCEL: 'Não, voltar',
    BUTTON_SUBMIT: 'Sim, salvar',
    FIELD_EMPTY: 'Vazio',
    DDCS_LIST: 'CDDs',
    VISIT_DAY: 'Dia da visita',
    DAY: 'Dia',
    DELIVERY_DAY: 'Dia da entrega',
    CLICK_AND_COLLECT: 'Click & collect',
    CLICK_AND_COLLECT_START_TIME: 'Início',
    CLICK_AND_COLLECT_END_TIME: 'Fim',
    DELIVERY_DAYS_AFTER_VISIT_DAY: 'Regra automática - Por dia da visita',
    DELIVERY_DAYS_OF_WEEK: 'Regra automática - Dias fixos',
    HOLIDAY_DELIVERY_DAY: 'Estratégia de feriados - Comportamento',
    LEAVE_MODAL_TITLE: 'Sair sem salvar?',
    LEAVE_MODAL_TEXT: 'Se sair sem salvar, todas as alterações serão perdidas.',
    LEAVE_MODAL_CANCEL: 'Não, cancelar',
    LEAVE_MODAL_CONFIRM: 'Sim, sair',
  },
  CustomSettingsModalConfirm: {
    MODAL_TITLE: 'Salvar configurações personalizadas?',
    MODAL_TEXT:
      'Isso substituirá as configurações padrão para POCs nas audiências e CDDs selecionados.',
  },
  CustomSettingsHelpModal: {
    MODAL_TITLE: 'Como criar uma audiência?',
    MODAL_TEXT_1: 'Você deve acessar o recurso de Audiências e seguir estes passos:',
    MODAL_LIST_1: '1. Selecione Criar nova audiência.',
    MODAL_LIST_2: '2. Faça o upload de um arquivo CSV com o grupo especificado de POCs.',
    MODAL_LIST_3: '3. Salve a audiência.',
    MODAL_TEXT_2:
      'Você pode selecionar a audiência criada após a conclusão do processamento do arquivo CSV.',
    CANCEL_BUTTON: 'Voltar',
    CONFIRM_BUTTON: 'Ir para audiências',
  },
  DDCsEditModalConfirm: {
    TITLE: 'Alteração de Parâmetros',
    CONFIRM_BUTTON: 'Confirmar',
    CANCEL_BUTTON: 'Cancelar',
    MAIN_TEXT: 'As seguintes alterações serão realizadas. Deseja continuar?',
    DDCS_LIST_TITLE: 'Unidades alteradas:',
    FIELDS_LIST_TITLE: 'Parâmetros Para Salvar:',
    FIELD_EXPIRATION: 'Expiração',
    FIELD_EMPTY: 'Nenhum',
  },
  DDCsAutoblock: {
    TABTITLE: 'Listagem de parâmetros de disponibilidade',
    BREADCRUMB: 'Parâmetros de disponibilidade de capacidade de entrega',
    TITLE: 'Parâmetros de disponibilidade de capacidade de entrega',
    SELECTED_DDCS_LABEL: 'Unidades Selecionadas',
    SEARCH_FIELD_PLACEHOLDER: 'Buscar por nome ou cód. da unidade',
    ADD_AUTOBLOCK_BUTTON: 'Adicionar parâmetro de disponibilidade',
    DATAGRID_COLUMN_DDC_CODE: 'Código',
    DATAGRID_COLUMN_DDC_NAME: 'Centro de Distribuição',
    DATAGRID_COLUMN_CREATED_AT: 'Criado em',
    DATAGRID_COLUMN_CREATED_BY: 'Criado por',
    DATAGRID_COLUMN_PERIOD: 'Período',
    DATAGRID_COLUMN_REASON: 'Parâmetro',
    DATAGRID_COLUMN_LIMIT: 'Quantidade Limite',
    DATAGRID_COLUMN_STRATEGY: 'Estratégias',
    DATAGRID_COLUMN_ACTIONS: 'Ações',
    DATAGRID_COLUMN_ACTION_DELETE: 'Remover parâmetro de disponibilidade',
    DATAGRID_COLUMN_ACTION_EDIT: 'Editar parâmetro de disponibilidade',
    DATEFORMAT: 'dd MMM, yyyy',
    DATAGRID_CELL_VISIT_LIMIT: '{value} visitas',
    DATAGRID_CELL_STRATEGIES_PLURAL: '{value} definidas',
    DATAGRID_CELL_STRATEGIES_SINGULAR: '{value} definida',
    UNABLE_TO_LOAD_LIST:
      'Erro ao carregar lista de parâmetros de disponibilidade. Por favor, tente mais tarde.',
  },
  DDCsAutoblockAddModal: {
    TITLE: 'Adicionar auto bloqueio',
    DDC_AUTOCOMPLETE_LABEL: 'Selecionar Unidades',
    PERIOD_TITLE: 'Definir Período',
    PERIOD_HINT:
      'Selecione um ou mais dias da semana, ou defina uma data específica para aplicar o auto bloqueio',
    RADIO_DAYS_OF_WEEK_LABEL: 'Dias da semana recorrentes',
    RADIO_SPECIFIC_DATE_LABEL: 'Data específica',
    SPECIFIC_DATE_FORMAT: 'dd/MM/yyyy',
    LIMIT_TITLE: 'Defina o número máximo de visitas da Unidade',
    LIMIT_HINT: 'Se o limite for atingido, a janela de entrega será bloqueada',
    LIMIT_INPUT_PLACEHOLDER: 'Informe o limite',
    CONFIRM_BUTTON: 'Adicionar auto bloqueio',
    TOAST_SUCCESS: 'Auto bloqueio(s) criado(s) com sucesso',
    TOAST_FAILURE: 'Erro ao criar Auto bloqueio(s)',
    TOAST_MIXED: 'Um ou mais Auto bloqueios não puderam ser criados',
  },
  DDCsAutoblockRemoveModal: {
    MODAL_TITLE: 'Excluir bloqueio automático?',
    MODAL_TEXT:
      'A janela de entrega deixará de seguir as definições de bloqueio automático. Caso este auto bloqueio já tenha sido aplicado, ele será revertido.',
    BUTTON_CONFIRM: 'Excluir',
    BUTTON_CANCEL: 'Voltar',
    TOAST_SUCCESS: 'Bloqueio automático excluído',
    TOAST_FAILURE: 'Erro ao excluir bloqueio automático',
  },
  DDCsHoliday: {
    TITLE: 'Gerenciar Feriados',
    BREADCRUMB: 'Gerenciar Feriados',
    SUBMIT_BUTTON: 'Salvar Configurações',
    DDCS_SIDELIST_TITLE: 'Unidades Selecionados',
    UNABLE_TO_EDIT_CDDS: 'Erro ao Salvar Configurações',
    TABLE_CELL_STATUS: 'Status',
    TABLE_CELL_ACTIONS: 'Ações',
    TABLE_CELL_DDC_CODE: 'Cód. Unidade',
    TABLE_CELL_DDC_NAME: 'Unidade',
    TABLE_CELL_DATE: 'Data',
    TABLE_CELL_WORKED_HOLIDAY: 'Feriado Trabalhado',
    TABLE_CELL_TYPE: 'Tipo de Feriado',
    TABLE_NO_DATA: 'Nenhum feriado encontrado',
    FILTER_TITLE: 'Buscar:',
    FILTER_START_DATE: 'Data Inicial',
    FILTER_END_DATE: 'Data Final',
    FILTER_SUBMIT: 'Buscar',
    FILTER_DATE_FORMAT: 'dd/MM/yyyy',
    ADD_NEW_TITLE: 'Adicionar Feriado:',
    ADD_NEW_DATE_FORMAT: 'dd/MM/yyyy',
    ADD_NEW_FIELD_DATE: 'Data do Feriado',
    ADD_NEW_FIELD_DDC: 'Unidade',
    ADD_NEW_FIELD_DDC_ALL: 'Selecionar Todos',
    ADD_NEW_SUBMIT: 'Adicionar',
    ADD_ERROR_DUPLICATED: 'Um ou mais feriados não foram adicionados pois estão duplicados',
    STATUS_EDITING: 'Feriado editado',
    STATUS_DELETING: 'Feriado marcado para exclusão',
    STATUS_INSERTING: 'Novo feriado',
    STATUS_PRISTINE: 'Feriado salvo',
    TYPE_LOCAL: 'Local',
    TYPE_STATE: 'Estadual',
    TYPE_NATIONAL: 'Nacional',
    ERROR_DUPLICATED_DATE: 'Já existe um feriado com esta data para esta unidade',
    DELETE_LABEL: 'Marcar para remoção',
    DELETE_LABEL_UNDO: 'Desmarcar para remoção',
    DELETE_LABEL_NEW: 'Remover novo',
    UNABLE_TO_SAVE_ADD_EDIT: 'Erro ao inserir/editar feriados',
    UNABLE_TO_SAVE_DELETE: 'Erro ao remover feriados',
    SAVING_ERROR_EARLIER: 'Data do feriado anterior a hoje',
    SAVING_ERROR_ALREADYEXISTING: 'Feriado duplicado',
    SAVING_ERROR_MISSINGFEDERATEDUNIT: 'Feriado sem Estado',
    SAVING_ERROR_NOTFOUND: 'Feriado não encontrado',
    UNABLE_TO_SAVE_DATA:
      'Erro ao salvar os feriados inseridos, editados e/ou removidos. Por favor, tente novamente.',
    SAVING_ERROR_WARNING_1:
      'Um ou mais feriados não puderam ser salvos. Veja os detalhes passando o mouse sobre ícone “',
    SAVING_ERROR_WARNING_2: '” nas linhas marcadas',
  },
  DDCsHolidayModalConfirm: {
    TITLE: 'Confirmar alterações de Feriados',
    CONFIRM_BUTTON: 'Confirmar',
    CANCEL_BUTTON: 'Cancelar',
    MAIN_TEXT:
      'Esta ação não poderá ser desfeita, e os feriados serão aplicados às janelas de entrega. Confirma as alterações?',
  },
  DDCMissingFields: {
    WARNING_MESSAGE:
      'A Unidade não possui informações de {fields} para ativação da janela de entrega. As informações da unidade devem ser regularizadas para a ativação.',
    FIELD_CITYCODE: 'Localidade',
    FIELD_TIMEZONEID: 'Fuso Horário',
    FIELD_GENERATEDELIVERYWINDOW: 'Geração de Janela de Entrega Habilitada',
    FIELD_DAYSOFWEEKSETTINGS: 'Data/hora de Expiração',
    FIELD_ACTIVE: 'CDD Ativo',
    DELIVERYDAYS_MESSAGE:
      'Este CDD não possui regra automática. Janelas de entrega não serão geradas para clientes que não tem sua própria semana de entrega configurada.',
  },
  DDCsPagination: {
    ROWS_PER_PAGE_LABEL: 'por página',
  },
  DDCsRules: {
    TITLE: 'Editar Regras de Inclusão/Exclusão',
    BREADCRUMB: 'Editar Regras de Inclusão/Exclusão',
    OWNER_LIST_TITLE: 'Unidades Selecionadas',
    OWNER_LIST_HIDE: 'Esconder',
    OWNER_LIST_VIEW_MORE: 'Ver mais',
    SUBMIT_BUTTON: 'Confirmar Parâmetros',
  },
  ClientsList: {
    TITLE: 'Lista de Clientes',
    BREADCRUMB: 'Janelas de Entrega',
    EDIT_SELECTION_BUTTON: 'Editar Seleção',
    RULES_SELECTION_BUTTON: 'Gerenciar regras de Exclusão/Inclusão',
    UNABLE_TO_LOAD_LIST: 'Erro ao carregar a lista de Clientes',
    DATAGRID_COLUMN_DDC_CODE: 'Cód. CDD',
    DATAGRID_COLUMN_DOCUMENT: 'Vendor Account Id',
    DATAGRID_COLUMN_NAME: 'Nome e código do cliente',
    DATAGRID_COLUMN_EXCEPTION: 'Tipo de configuração',
    DATAGRID_EXCEPTION_POC_LEVEL: 'Nível POC',
    DATAGRID_EXCEPTION_DEFAULT: 'Padrão',
    DATAGRID_EXCEPTION_CUSTOM: 'Personalizado',
    DATAGRID_ICON_FREE: 'Grátis\nOrigem: {origin}',
    DATAGRID_ICON_PAID:
      'Flex\nTaxa de entrega: {addAmount}\nPedido mínimo: {minValue}\nOrigem: {origin}',
    [`DATAGRID_ORIGIN_${ClientDaysOfWeekOrigins.Unknown}`]: 'Desconhecido',
    [`DATAGRID_ORIGIN_${ClientDaysOfWeekOrigins.VisitDay}`]: 'Dia da visita',
    [`DATAGRID_ORIGIN_${ClientDaysOfWeekOrigins.NearbyPOC}`]: 'POC Próxima',
    [`DATAGRID_ORIGIN_${ClientDaysOfWeekOrigins.CurrentDay}`]: 'Dia atual',
    [`DATAGRID_ORIGIN_${ClientDaysOfWeekOrigins.FixedDays}`]: 'Dias fixos',
    [`DATAGRID_ORIGIN_${ClientDaysOfWeekOrigins.Exception}`]: 'Exceção',
    DATAGRID_CELL_ACTIONS_EDIT_TOOLTIP: 'Editar',
    DATAGRID_CELL_ACTIONS_RULES_TOOLTIP: 'Gerenciar regras',
    DATAGRID_CELL_ACTIONS_DELIVERY_WINDOWS_TOOLTIP: 'Exibir janelas de entrega',
    LINK_UPLOAD_CSV: 'Upload/Download CSV de Clientes',
    SEARCH_LABEL: 'Buscar',
    SEARCH_FILTER_LABEL: 'Filtro',
    SEARCH_BUTTON: 'Buscar',
    SEARCH_FIELD_DDC_CODE: 'Cód. CDD',
    SEARCH_FIELD_DDC_NAME: 'Nome CDD',
    SEARCH_FIELD_CLIENT_CODE: 'Cód. Cliente',
    SEARCH_FIELD_CLIENT_DOCUMENT: 'Vendor Account Id',
    SEARCH_FIELD_CLIENT_NAME: 'Nome Cliente',
    DISABLED_DDC_TOOLTIP: 'Geração de janelas de entrega desabilitada para este CD.',
  },
  ViewDeliveryWindowsModal: {
    TITLE: 'Janelas de Entrega',
    CLOSE_BUTTON: 'Fechar',
    TABLE_CELL_DELIVERY_WINDOW_ID: 'Delivery Window ID',
    TABLE_CELL_DATE: 'Data',
    TABLE_CELL_EXPIRATION_DATE: 'Data de Expiração',
    TABLE_CELL_UPDATE_DATE: 'Data de Atualização',
    TABLE_CELL_FLEXIBLE_DELIVERY: 'Flexible Delivery',
    TABLE_NO_DATA: 'Nenhuma janela de entrega encontrada para este Cliente',
    UNABLE_TO_GET_DATA: 'Erro ao consultar janelas de entrega',
  },
  ViewDeliveryWindowsModalV2: {
    SUBTITLE: 'Janelas de entrega',
    TAB_ACTIVES: 'Ativas',
    TAB_DELETED: 'Deletadas',
    SEARCH_PLACEHOLDER: 'Buscar por ID',
    COLUMN_ID: 'ID',
    COLUMN_DELIVERY_DATE: 'Data de entrega',
    COLUMN_DELIVERY_TYPE: 'Tipo de entrega',
    COLUMN_GENERATED_BY: 'Gerada por',
    COLUMN_DELETED_BY: 'Deletada por',
    COLUMN_EXPIRATION_DATE: 'Data de expiração',
    COLUMN_UPDATE_DATE: 'Data de atualização',
    [`DELIVERY_TYPE_${ClientDayOfWeekType.flex}`]: 'Flex',
    [`DELIVERY_TYPE_${ClientDayOfWeekType.free}`]: 'Grátis',
    DELIVERY_TYPE_FLEX_MESSAGE: 'Taxa: {addAmount} • Pedido mínimo: {minValue}',
    DELIVERY_TYPE_FREE_MESSAGE: 'Sem taxa e pedido mínimo',
    [`ORIGIN_${DeliveryWindowOrigin.autoblock}`]: 'Capacidade de entrega',
    [`ORIGIN_${DeliveryWindowOrigin.daysOfWeek}`]: 'Dia da semana',
    [`ORIGIN_${DeliveryWindowOrigin.holiday}`]: 'Feriado',
    [`ORIGIN_${DeliveryWindowOrigin.inclusionRule}`]: 'Regra de inclusão',
    [`ORIGIN_${DeliveryWindowOrigin.exclusionRule}`]: 'Regra de exclusão',
    [`ORIGIN_${DeliveryWindowOrigin.removedDayOfWeek}`]: 'Dia da semana Removido',
    [`ORIGIN_${DeliveryWindowOrigin.replacedDueHoliday}`]: 'Substituição Feriado',
    [`ORIGIN_${DeliveryWindowOrigin.holidayDeleted}`]: 'Feriado Removido',
    [`ORIGIN_${DeliveryWindowOrigin.ruleDeleted}`]: 'Regra excluida',
    [`ORIGIN_${DeliveryWindowOrigin.ddcDisabled}`]: 'CD Desabilitado',
    [`ORIGIN_${DeliveryWindowOrigin.outOfWindowRange}`]: 'Fora de Range de Janelas',
    [`ORIGIN_${DeliveryWindowOrigin.integrationIssue}`]: 'Problema na Integração',
    [`ORIGIN_${DeliveryWindowOrigin.outOfHolidayCalculation}`]: 'Feriado',
    [`ORIGIN_${DeliveryWindowOrigin.autoblockStrategyRemoved}`]: 'Capacidade de entrega removida',
    [`ORIGIN_${DeliveryWindowOrigin.autoblockDeleted}`]: 'Capacidade de entrega removida',
    [`ORIGIN_${DeliveryWindowOrigin.capacityStrategy}`]: 'Capacidade de entrega',
    [`ORIGIN_${DeliveryWindowOrigin.deliveryScheduledIdUpdated}`]: 'ScheduleId atualizado',
    [`ORIGIN_${DeliveryWindowOrigin.deliveryFrequencyChanged}`]: 'Frequência de entrega alterada',
    [`ORIGIN_${DeliveryWindowOrigin.visitDateChanged}`]: 'Data da visita alterada',
    [`ORIGIN_${DeliveryWindowOrigin.expiredDeliveryWindow}`]: 'Janela de entrega expirada',
    [`ORIGIN_${DeliveryWindowOrigin.unknown}`]: 'Desconhecido',
    HAS_AUTOBLOCK_CHANGE_TOOLTIP:
      'Janela de entrega alterada por um Parâmetro de Capacidade de Entrega',
    DATEFORMAT: 'EEE, dd MMM, yyyy',
    DATETIMEFORMAT: 'EEE, dd MMM, yyyy, HH:mm',
    [`ERROR_${DeliveryWindowsModalError.accountIdNotFound}`]:
      'O contrato desse cliente não está vinculado a nenhum AccountId, não foi possível consultar as janelas de entrega',
    MINVALUE_DIVERGENCE_TOOLTIP:
      'Divergência no pedido mínimo detectada. O valor deveria ser {minValueDivergence}. Por favor, aguarde alguns minutos até que os dados sejam integrados ou reenvie as janelas de entrega deste cliente.',
    ADDAMOUNT_DIVERGENCE_TOOLTIP:
      'Diferença na taxa detectada. O valor deveria ser {addAmountDivergence}. Por favor, aguarde alguns minutos até que os dados sejam integrados ou reenvie as janelas de entrega deste cliente.',
    MINVALUE_ADDAMOUNT_DIVERGENCE_TOOLTIP:
      'Diferenças na Taxa e Pedido mínimo detectadas. Os valores deveriam ser: Taxa {addAmountDivergence} e Pedido mínimo {minValueDivergence}. Por favor, aguarde alguns minutos até que os dados sejam integrados ou reenvie as janelas de entrega deste cliente.',
    EXPIRATIONDATE_DIVERGENCE_TOOLTIP:
      'Diferença na data de expiração detectada. O valor deveria ser {expirationDateDivergence}. Por favor, aguarde alguns minutos até que os dados sejam integrados ou reenvie as janelas de entrega deste cliente.',
  },
  ImportsAndExports: {
    TITLE: 'Importações e Exportações',
    BREADCRUMB: 'Importações e Exportações',
    TAB_CLIENTS: 'Clientes',
    TAB_AUTOMATED_EXCEPTIONS: 'Regras',
    TAB_UPLOAD: 'Gerenciar',
    TAB_DOWNLOAD: 'Requisições de pacotes de dados',
    TAB_CLIENTS_UPLOAD: 'Subir Base de Clientes',
    TAB_CLIENTS_DOWNLOAD: 'Baixar Base de Clientes',
    TAB_AUTOMATED_EXCEPTIONS_UPLOAD: 'Subir Base de Regras',
    TAB_AUTOMATED_EXCEPTIONS_DOWNLOAD: 'Baixar Base de Regras',
    UPLOAD_FILTER_SEARCH_PLACEHOLDER: 'Buscar por nome de arquivo ou usuário',
    DOWNLOAD_FILTER_SEARCH_PLACEHOLDER: 'Buscar por usuário',
    TITLE_CLIENTS_UPLOAD: 'Histórico de Uploads de Base de Clients',
    TITLE_CLIENTS_DOWNLOAD: 'Bases de Clientes Geradas',
    TITLE_AUTOMATED_EXCEPTIONS_UPLOAD:
      'Histórico de Uploads de Base de Regras de Inclusão/Exclusão',
    TITLE_AUTOMATED_EXCEPTIONS_DOWNLOAD: 'Bases de Regras Geradas',
    LINK_DOWNLOAD_DEFAULT_CLIENTS_FILE: 'Baixar modelo',
    LINK_DOWNLOAD_DEFAULT_RULES_FILE: 'Baixar modelo',
    LINK_REQUEST_CLIENTS_BASE: 'Solicitar Base de Clientes',
    LINK_REQUEST_AUTOMATED_EXCEPTIONS_BASE: 'Solicitar Base de Regras',
    UNABLE_TO_UPLOAD_CSV: 'Erro ao enviar o CSV.',
    UPLOAD_SUCCESS: 'Arquivo enviado com sucesso!',
    REQUEST_CLIENTS_BASE_SUCCESS:
      'A base de clientes foi solicitada e em breve estará disponível para download',
    REQUEST_CLIENTS_BASE_ERROR:
      'Uma solicitação ainda está em processamento. Por favor, aguarde alguns minutos e tente novamente',
    REQUEST_AUTOMATED_EXCEPTIONS_BASE_SUCCESS:
      'A base de regras foi solicitada e em breve estará disponível para download',
    REQUEST_AUTOMATED_EXCEPTIONS_BASE_ERROR:
      'Uma solicitação ainda está em processamento. Por favor, aguarde alguns minutos e tente novamente',
    HISTORY_DATAGRID_COLUMN_NAME: 'Arquivo',
    HISTORY_DATAGRID_COLUMN_UPLOAD_DATE: 'Subido em',
    HISTORY_DATAGRID_COLUMN_LINES: 'Linhas',
    HISTORY_DATAGRID_COLUMN_LINES_SUCCESS: 'Sucesso',
    HISTORY_DATAGRID_COLUMN_LINES_FAIL: 'Erros',
    HISTORY_DATAGRID_COLUMN_STATUS: 'Status',
    HISTORY_DATAGRID_COLUMN_USER: 'Subido por',
    HISTORY_DATAGRID_ACTION_DOWNLOAD: 'Baixar Arquivo',
    HISTORY_DATAGRID_ACTION_DOWNLOAD_FAIL: 'Baixar Arquivo com erros',
    UNABLE_TO_LOAD_HISTORY_LIST: 'Erro ao carregar o histórico de Uploads de CSV',
    UNABLE_TO_LOAD_DOWNLOAD_BASE_LIST: 'Erro ao carregar os arquivos para download',
    UNABLE_TO_DOWNLOAD_FILE: 'Erro ao baixar o arquivo',
    UPLOAD_STATUS_awaitingProcessing: 'Na fila',
    UPLOAD_STATUS_processing: 'Processando',
    UPLOAD_STATUS_processed: 'Analisado',
    UPLOAD_STATUS_error: 'Falha ao processar',
    UPLOAD_STATUS_invalidFile: 'Arquivo inválido',
    UPLOAD_DATE_FORMAT: 'dd MMM, yyyy, HH:mm OOOO',
    UPLOAD_DATE_SENT: 'Enviado às',
    DOWNLOAD_BASE_DATAGRID_COLUMN_REQUEST_DATE: 'Requisitado em',
    DOWNLOAD_BASE_DATAGRID_COLUMN_TYPE: 'Tipo',
    [`DOWNLOAD_BASE_DATAGRID_COLUMN_TYPE_${ClientFileType.downloadClients}`]: 'Base de Clientes',
    [`DOWNLOAD_BASE_DATAGRID_COLUMN_TYPE_${ClientFileType.downloadClientAudit}`]: 'Auditoria',
    DOWNLOAD_BASE_DATAGRID_COLUMN_STATUS: 'Status',
    DOWNLOAD_BASE_DATAGRID_COLUMN_USER: 'Requisitado por',
    DOWNLOAD_BASE_DATAGRID_ACTION_DOWNLOAD: 'Baixar Arquivo',
    DOWNLOAD_BASE_STATUS_awaitingProcessing: 'Na fila',
    DOWNLOAD_BASE_STATUS_processing: 'Processando',
    DOWNLOAD_BASE_STATUS_processed: 'Pronto para Download',
    DOWNLOAD_BASE_STATUS_error: 'Falha ao processar',
    DOWNLOAD_BASE_DATE_FORMAT: 'dd MMM, yyyy, HH:mm OOOO',
    DOWNLOAD_BASE_DATE_SENT: 'Enviado',
    UPLOAD_CLIENT_DATE_FIELDS_DENIED:
      'Você não possui permissão para alterar campos de "Data" e "Tipo de Entrega". Estes campos serão ignorados ao subir um CSV.',
    UPLOAD_CLIENT_FLEX_FIELDS_DENIED:
      'Você não possui permissão para alterar campos de "Pedido Mínimo" e "Taxa de entrega". Estes campos serão ignorados ao subir um CSV.',
    UPLOAD_CLIENT_CLICK_AND_COLLECT_FIELDS_DENIED:
      'Você não possui permissão para alterar campos de "Click & Collect". Estes campos serão ignorados ao subir um CSV.',
    UPLOAD_AUTOMATED_EXCEPTIONS_DATE_FIELDS_DENIED:
      'Você não possui permissão para alterar campos de "Data", "Expiração" e "Tipo de Entrega". Estes campos serão ignorados ao subir um CSV.',
    UPLOAD_AUTOMATED_EXCEPTIONS_FLEX_FIELDS_DENIED:
      'Você não possui permissão para alterar campos de "Pedido Mínimo" e "Taxa de entrega". Estes campos serão ignorados ao subir um CSV.',
    UPLOAD_AUTOMATED_EXCEPTIONS_DDC_DENIED:
      'Você não possui permissão para alterar Exceções de Unidades. Estas exceções serão ignoradas ao subir um CSV.',
    UPLOAD_AUTOMATED_EXCEPTIONS_CLIENT_DENIED:
      'Você não possui permissão para alterar Exceções de Clientes. Estas exceções serão ignoradas ao subir um CSV.',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_COLUMN_REQUEST_DATE: 'Data da requisição',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_COLUMN_TYPE: 'Tipo',
    [`DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_COLUMN_TYPE_${RuleDownloadFileSubType.database}`]:
      'Base de Regras',
    [`DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_COLUMN_TYPE_${RuleDownloadFileSubType.audit}`]:
      'Auditoria',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_COLUMN_STATUS: 'Status',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_COLUMN_USER: 'Usuário',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_ACTION_DOWNLOAD: 'Baixar Arquivo',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_STATUS_awaitingProcessing: 'Na fila',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_STATUS_processing: 'Processando',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_STATUS_processed: 'Pronto para Download',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_STATUS_error: 'Falha ao processar',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATE_FORMAT: 'dd/MM/yyyy HH:mm:ss',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATE_SENT: 'Enviado',
    REQUEST_AUDIT_FILE_BUTTON: 'Baixar arquivo CSV único',
    REQUEST_AUDIT_FILE_SUCCESS:
      'O arquivo foi solicitado e em breve estará disponível para download',
    REQUEST_AUDIT_FILE_ERROR: 'Erro ao solicitar o arquivo',
    UPLOAD_MODAL_BUTTON_CLIENTS: 'Subir arquivo de clientes',
    UPLOAD_MODAL_BUTTON_RULES: 'Subir arquivo de regras',
    UPLOAD_MODAL_TITLE: 'Subir arquivo CSV',
    UPLOAD_MODAL_DROPZONE_PLACEHOLDER: 'Solte aqui seu arquivo',
    UPLOAD_MODAL_DROPZONE_ERROR_EMPTY: 'Por favor, selecione um arquivo válido',
    UPLOAD_MODAL_DROPZONE_ERROR_SIZE: 'O arquivo deve ser um CSV e conter no máximo 50Mb',
    UPLOAD_MODAL_DROPZONE_BUTTON: 'Procurar',
    UPLOAD_MODAL_CONFIRM: 'Subir',
  },
  RuleCSVUpload: {
    TITLE: 'Upload de arquivo de regras',
    BREADCRUMB: 'Upload de arquivo de regras',
    CARD_TITLE: 'Upload de arquivo',
    MAIN_LIST_TITLE: 'Baixe o modelo CSV, revise as instruções e faça o upload do seu arquivo.',
    MAIN_LIST_ITEM_1: 'Os pontos e vírgulas devem ser usados apenas como separadores de coluna.',
    MAIN_LIST_ITEM_2: 'Verifique a presença de zeros à esquerda nos IDs.',
    MAIN_LIST_ITEM_3: 'Salve no formato CSV.',
    RULES_TARGET_LABEL: 'Destino das regras',
    RULES_TARGET_HINT: 'Selecione o destino para suas regras enviadas.',
    RULES_TARGET_DDC: 'CDD',
    RULES_TARGET_CLIENT: 'Clientes',
    INSTRUCTIONS_TITLE: 'Revise as instruções do arquivo',
    INSTRUCTIONS_ITEM_MOV: 'Minimum_Order_Value',
    INSTRUCTIONS_ITEM_MOV_1: 'Valor que deve ser atingido para entrega gratuita.',
    INSTRUCTIONS_ITEM_MOV_2: 'Use um ponto como separador decimal.',
    INSTRUCTIONS_ITEM_FEE: 'Additional_Fee',
    INSTRUCTIONS_ITEM_FEE_1: 'Taxa cobrada quando o valor mínimo não é atingido.',
    INSTRUCTIONS_ITEM_FEE_2: 'Use um ponto como separador decimal.',
    INSTRUCTIONS_ITEM_DELIVERY_TYPE: 'Delivery_Type',
    INSTRUCTIONS_ITEM_DELIVERY_TYPE_1: 'Filtra os clientes do DC que terão a regra aplicada.',
    INSTRUCTIONS_ITEM_DELIVERY_TYPE_2:
      'Insira 0 ou NO para clientes sem data de entrega, 1 ou FREE para clientes com entrega grátis e 2 ou FLEX para clientes com entrega flex',
    INSTRUCTIONS_ITEM_DELIVERY_TYPE_3:
      'Se permanecer em branco, a regra irá ser aplicada para todos os clientes do DC.',
    INSTRUCTIONS_ITEM_RULE_TYPE: 'Rule_Type',
    INSTRUCTIONS_ITEM_RULE_TYPE_1:
      'Uma coluna para adicionar ou excluir uma regra de inclusão ou exclusão.',
    INSTRUCTIONS_ITEM_RULE_TYPE_2:
      'Insira 0 ou EXCLUSION, 1 ou INCLUSION_FREE, 2 ou INCLUSION_FLEX, 3 ou DELETE_RULE.',
    INSTRUCTIONS_ITEM_EXPIRATION_DATE: 'Expiration_Date',
    INSTRUCTIONS_ITEM_EXPIRATION_DATE_1: 'A data em que uma regra expira.',
    INSTRUCTIONS_ITEM_EXPIRATION_DATE_2: 'O formato da data é aaaa-mm-dd.',
    INSTRUCTIONS_ITEM_EXPIRATION_TIME: 'Expiration_Time',
    INSTRUCTIONS_ITEM_EXPIRATION_TIME_1: 'A hora em que uma regra expira.',
    INSTRUCTIONS_ITEM_EXPIRATION_TIME_2: 'O formato da hora é hh:mm.',
    TEMPLATE_DOWNLOAD_BUTTON: 'Baixar Modelo',
    UPLOAD_HINT:
      'Arraste e solte um arquivo do seu computador ou faça o upload.\nAceitamos apenas arquivos CSV. Tamanho máximo do arquivo: 50MB',
    UPLOAD_BUTTON: 'Subir',
  },
  ClientCSVUpload: {
    TITLE: 'Upload de arquivo de clientes',
    BREADCRUMB: 'Upload de arquivo de clientes',
    CARD_TITLE: 'Upload de arquivo',
    MAIN_LIST_TITLE: 'Baixe o modelo CSV, revise as instruções e faça o upload do seu arquivo.',
    MAIN_LIST_ITEM_1: 'Os pontos e vírgulas devem ser usados apenas como separadores de coluna.',
    MAIN_LIST_ITEM_2: 'Verifique a presença de zeros à esquerda nos IDs.',
    MAIN_LIST_ITEM_3: 'Salve no formato CSV.',
    INSTRUCTIONS_TITLE: 'Revise as instruções do arquivo',
    INSTRUCTIONS_ITEM_DAYS_WEEK: 'Days of the week',
    INSTRUCTIONS_ITEM_DAYS_WEEK_1: 'Indica quais dias da semana o cliente terá entrega.',
    INSTRUCTIONS_ITEM_DAYS_WEEK_2:
      'Insira 0 ou NO para dias sem entrega, 1 ou FREE para dias de entrega gratuita.',
    INSTRUCTIONS_ITEM_DAYS_WEEK_2_FLEX:
      'Insira 0 ou NO para dias sem entrega, 1 ou FREE para dias de entrega gratuita e 2 ou FLEX para dias de entrega flexível.',
    INSTRUCTIONS_ITEM_MOV: 'Minimum_Order_Value',
    INSTRUCTIONS_ITEM_MOV_1: 'Valor que deve ser atingido para entrega gratuita.',
    INSTRUCTIONS_ITEM_MOV_2: 'Use um ponto como separador decimal.',
    INSTRUCTIONS_ITEM_FEE: 'Additional_Fee',
    INSTRUCTIONS_ITEM_FEE_1: 'Taxa cobrada quando o valor mínimo não é atingido.',
    INSTRUCTIONS_ITEM_FEE_2: 'Use um ponto como separador decimal.',
    INSTRUCTIONS_ITEM_REFERENCE_DATE: 'Reference_Date',
    INSTRUCTIONS_ITEM_REFERENCE_DATE_1: 'Data usada para calcular a data de entrega.',
    INSTRUCTIONS_ITEM_REFERENCE_DATE_2: 'O formato da data é aaaa-mm-dd.',
    INSTRUCTIONS_ITEM_REFERENCE_DATE_3:
      'Se Delivery_Frequency for 7 ou WEEKLY, este campo é opcional.',
    INSTRUCTIONS_ITEM_REFERENCE_DATE_4:
      'Se este campo não for preechido, nenhuma alteração será realizada nesse parâmetro',
    INSTRUCTIONS_ITEM_DELIVERY_FREQUENCY: 'Delivery_Frequency',
    INSTRUCTIONS_ITEM_DELIVERY_FREQUENCY_1: 'A frequência de entrega para cada cliente.',
    INSTRUCTIONS_ITEM_DELIVERY_FREQUENCY_2:
      'Insira 7 ou WEEKLY, 14 ou BIWEEKLY, 21 ou TRIWEEKLY, 28 ou MONTHLY.',
    INSTRUCTIONS_ITEM_RESET_TO_DDC_DEFAULT: 'Reset_To_DDC_Default',
    INSTRUCTIONS_ITEM_RESET_TO_DDC_DEFAULT_1: 'Insira YES ou NO.',
    INSTRUCTIONS_ITEM_RESET_TO_DDC_DEFAULT_2:
      'Se YES, deixe Minimum_Order_Value, Additional_Fee e dias da semana em branco.',
    INSTRUCTIONS_ITEM_CLICK_AND_COLLECT: 'Click_And_Collect',
    INSTRUCTIONS_ITEM_CLICK_AND_COLLECT_1: 'Permite a coleta de pedidos no local.',
    INSTRUCTIONS_ITEM_CLICK_AND_COLLECT_2: 'Insira YES ou NO.',
    TEMPLATE_DOWNLOAD_BUTTON: 'Baixar Modelo',
    UPLOAD_HINT:
      'Arraste e solte um arquivo do seu computador ou faça o upload.\nAceitamos apenas arquivos CSV. Tamanho máximo do arquivo: 50MB',
    UPLOAD_BUTTON: 'Subir',
  },
  ClientUploadGuidelines: {
    LIST_TITLE: 'Regras para ter um upload bem sucedido:',
    LIST_ITEM_1: 'Ponto e virgula são os separadores de coluna',
    LIST_ITEM_2: 'Ponto é separador de decimal',
    LIST_ITEM_3: 'Tamanho máximo de arquivo: 50MB',
    LIST_ITEM_4: 'Não remova nenhuma coluna ou altere a ordem das colunas do arquivo modelo.',
    LIST_ITEM_5:
      'Quando salvar o arquivo, selecione a opção “Valores Separados por Virgulas (CSV)”',
    LIST_ITEM_6:
      'Se você preparou o arquivo a partir de uma exportação de clientes do Delivery Admin, remova a coluna “exception” antes de importar o arquivo.',
    LIST_ITEM_7:
      'Recomendamos formatar as colunas de identificação do cliente como texto para que o Excel não remova zeros a esquerda',
    LIST_ITEM_8:
      'Os campos BaselineDate, DeliveryFrequency e ReturnToDDCDefault não são obrigatórios, importa-los em branco não irá realizar nenhuma atualização nesses parâmetros no banco de dados.',
    LIST_ITEM_9: 'Formato de data: aaaa-mm-dd',
    LIST_ITEM_10: 'O campo DeliveryFrequency aceita apenas os valores: 7, 14 ou 28.',
    LIST_ITEM_11:
      'Se o campo ReturnToDDCDefault for preenchido com YES, então as colunas de dias da semana e valores de pedido mínimo e taxa deverão ser mantidos em branco',
  },
  RuleUploadGuidelines: {
    LIST_TITLE: 'Regras para ter um upload bem sucedido:',
    LIST_ITEM_1: 'Ponto e virgula são os separadores de coluna',
    LIST_ITEM_2: 'Ponto é separador de decimal',
    LIST_ITEM_3: 'Tamanho máximo de arquivo: 50MB',
    LIST_ITEM_4: 'Não remova nenhuma coluna ou altere a ordem das colunas do arquivo modelo.',
    LIST_ITEM_5:
      'Quando salvar o arquivo, selecione a opção “Valores Separados por Virgulas (CSV)”',
    LIST_ITEM_6: 'Formato de data: dd/mm/aaaa',
    LIST_ITEM_7: 'Formato de hora: hh:mm',
    LIST_ITEM_8:
      'Recomendamos formatar as colunas de identificação do cliente como texto para que o Excel não remova zeros a esquerda',
  },
  ClientEdit: {
    TITLE: 'Editar configurações a nível de POC',
    BREADCRUMB: 'Editar configurações a nível de POC',
    SUBMIT_BUTTON: 'Salvar configurações',
    DELIVERY_WINDOW_TITLE: 'Janela de Entrega',
    DELIVERY_WINDOW_TEXT:
      'Defina as janelas de entrega do cliente por frequência, clique e retire e dias de entrega. Essas regras substituem as regras automáticas na página de Configurações de edição',
    DDC_SETTINGS_TITLE: 'Configurações do CDD',
    DDC_SETTINGS_TYPE_CUSTOM: 'Configurações personalizadas',
    DDC_SETTINGS_TYPE_DEFAULT: 'Configurações padrão',
    DDC_SETTINGS_TEXT:
      'Para editar as configurações do CDD que abastece este POC, vá para Gestão de CDD.',
    DDC_CODE_LABEL: 'Código CDD',
    DDC_NAME_LABEL: 'Nome CDD',
    SETTINGS_TYPE_LABEL: 'Tipo de configuração',
    SETTINGS_TYPE_TOOLTIP:
      'As configurações padrão são aplicadas aos POCs dentro dos DCs selecionados. Configurações personalizadas as sobrescrevem para audiências e CDDs específicos.',
    AUDIENCE_NAME_LABEL: 'Nome da configuração',
    AUDIENCE_NAME_TOOLTIP:
      'O nome da configuração é o mesmo que o nome da audiência selecionada durante a criação da configuração.',
    DDC_SETTINGS_BULK_TEXT:
      'O tipo de configuração para cada POC está disponível na tela anterior ou por audiência na gestão de CDD.',
    DDC_SETTINGS_BUTTON: 'Ir para a gestão de CDD',
    EXCEPTION_BUTTON_ENABLED: 'Remover configurações de dias de entrega',
    EXCEPTION_BUTTON_DISABLED: 'Adicionar configurações de dias de entrega',
    DELIVERY_DAYS_LABEL: 'Dias de entrega',
    DELIVERY_DAYS_TEXT: 'Defina o tipo de entrega, pedido mínimo e taxa para os dias da semana.',
    FIELD_MINVALUE_LABEL: 'Pedido Mínimo',
    FIELD_ADDAMMOUNT_LABEL: 'Taxa',
    FIELD_HAS_DELIVERY_LABEL: 'Tem entrega',
    FIELD_DELIVERY_TYPE_LABEL: 'Tipo de Entrega',
    FIELD_DELIVERY_TYPE_TOOLTIP:
      'Embora as entregas regulares sejam gratuitas, elas exigem uma taxa para exceções. Entregas flexíveis fora das datas regulares têm uma taxa.',
    FIELD_DELIVERY_TYPE_VALUE_EMPTY: 'Sem entrega',
    FIELD_DELIVERY_TYPE_VALUE_FLEX: 'Flex',
    FIELD_DELIVERY_TYPE_VALUE_FREE: 'Grátis',
    FIELD_MONEY_ERROR: 'Valor deve ser maior que zero',
    FIELD_DELIVERY_FREQUENCY_LABEL: 'Frequência de Entrega',
    FIELD_DELIVERY_FREQUENCY_7: 'A cada semana',
    FIELD_DELIVERY_FREQUENCY_14: 'A cada 2 semanas',
    FIELD_DELIVERY_FREQUENCY_21: 'A cada 3 semanas',
    FIELD_DELIVERY_FREQUENCY_28: 'A cada 4 semanas',
    CLIENTS_LIST_TITLE: 'POCs Selecionados',
    CLIENTS_LIST_TEXT:
      'As configurações salvas serão aplicadas às janelas de entrega do POC selecionado.',

    CLIENTS_LIST_VIEW_MORE: 'Ver Mais',
    CLIENTS_LIST_HIDE: 'Esconder',
    CLICK_AND_COLLECT_ENABLED: 'Click & Collect Habilitado',
    VISIT_DATE: 'Dia da Visita',
    NO_VISIT_DATE: 'Sem dia da visita',
    BASELINE_DATE: 'Data base',
    BASELINE_DATE_TOOLTIP:
      'A data base é usada como referência para calcular janelas de entrega de frequência quinzenal e mensal',
    BASELINE_DATE_FORMAT: 'dd/MM/yyyy',
    UNABLE_TO_GET_CLIENT_INFO: 'Erro ao buscar dados do cliente',
    NO_DELIVERY_monday: 'Sem entregas na ',
    NO_DELIVERY_tuesday: 'Sem entregas na ',
    NO_DELIVERY_wednesday: 'Sem entregas na ',
    NO_DELIVERY_thursday: 'Sem entregas na ',
    NO_DELIVERY_friday: 'Sem entregas na ',
    NO_DELIVERY_saturday: 'Sem entregas no ',
    NO_DELIVERY_sunday: 'Sem entregas no ',
    RESEND_DELIVERY_WINDOWS_TITLE: 'Reenviar janelas de entrega',
    RESEND_DELIVERY_WINDOWS_TEXT:
      'Reenviar manualmente as informações das janelas de entrega para sincronizar com o aplicativo BEES Customer.',
    BULK_WARNING:
      'Ao editar múltiplos clientes, as alterações nos campos são aplicadas a todos os clientes selecionados.',
  },
  ClientEditModalConfirm: {
    TITLE: 'Salvar configurações?',
    CONFIRM_BUTTON: 'Sim, salvar',
    CANCEL_BUTTON: 'Não, voltar',
    MAIN_TEXT: 'Esta ação atualizará as janelas de entrega para os clientes selecionados.',
  },
  ClientPatchModalConfirm: {
    TITLE: 'Confirmar alterações de Click & Collect para este(s) PDV(s)',
    CONFIRM_BUTTON: 'Confirmar',
    CANCEL_BUTTON: 'Cancelar',
    MAIN_TEXT: 'Ao salvar, as alterações de Click & Collect serão salvas. Confirma as alterações?',
  },
  ClientEditResponseMessage: {
    SUCCESS: 'Clientes atualizados com sucesso',
    NOT_FOUND_CLIENTS: 'Clientes não encontrados:',
    DUPLICATED_CLIENTS: 'Clientes duplicados:',
    INVALID_MINVALUE_OR_ADDAMOUNT_CLIENTS: 'Valores inválidos de Pedido Mínimo e/ou Taxa',
    DEFAULT_ERROR: 'Erro ao salvar as informações',
  },
  ClientRules: {
    TITLE: 'Editar Regras de Inclusão/Exclusão',
    BREADCRUMB: 'Editar Regras de Inclusão/Exclusão',
    OWNER_LIST_TITLE: 'PDVs Selecionados',
    OWNER_LIST_HIDE: 'Esconder',
    OWNER_LIST_VIEW_MORE: 'Ver mais',
    SUBMIT_BUTTON: 'Confirmar Parâmetros',
  },
  RulesModalConfirm: {
    TITLE: 'Confirmar alterações de regras de Inclusão/Exclusão para estes clientes?',
    CONFIRM_BUTTON: 'Confirmar',
    CANCEL_BUTTON: 'Cancelar',
    MAIN_TEXT:
      'Esta ação não poderá ser desfeita, e os feriados serão aplicados às janelas de entrega. Confirma as alterações?',
  },
  RulesForm: {
    STATUS_EDITING: 'Regra editada',
    STATUS_DELETING: 'Regra marcada para exclusão',
    STATUS_INSERTING: 'Nova regra',
    STATUS_PRISTINE: 'Regra salva',
    TABLE_CELL_STATUS: 'Status',
    TABLE_CELL_NAME: 'Nome',
    TABLE_CELL_TYPE: 'Clientes Da Unidade',
    TABLE_CELL_RULE_TYPE: 'Tipo de Exceção',
    TABLE_CELL_DATE: 'Data',
    TABLE_CELL_EXPIRATION: 'Data de Expiração',
    TABLE_CELL_FLEXIBLE: 'Tipo de Janela',
    TABLE_CELL_MINVALUE: 'Pedido Mínimo',
    TABLE_CELL_ADDAMOUNT: 'Taxa',
    TABLE_CELL_ACTIONS: 'Ações',
    TABLE_NO_DATA: 'Nenhuma regra encontrada com estes filtros',
    RULE_TYPE_INCLUSION: 'Inclusão',
    RULE_TYPE_EXCLUSION: 'Exclusão',
    DELETE_LABEL: 'Marcar para remoção',
    DELETE_LABEL_UNDO: 'Desmarcar para remoção',
    DELETE_LABEL_NEW: 'Remover novo',
    INVALID_DATE_MESSAGE: 'Data Inválida',
    FIELD_DATE_FORMAT: 'dd/MM/yyyy',
    FIELD_EXPIRATION_FORMAT: 'dd/MM/yyyy HH:mm',
    FIELD_EXPIRATION_EXPIRED: 'A data selecionada já está expirada',
    FIELD_EXPIRATION_DDC: 'Usando expiração padrão do CDD',
    FIELD_DELIVERY_TYPE_VALUE_FLEX: 'Flex',
    FIELD_DELIVERY_TYPE_VALUE_FREE: 'Grátis',
    FIELD_TYPE_ALL_DDC_CLIENTS: 'Todos',
    FIELD_TYPE_ALL_DDC_FLEXIBLE_CLIENTS: 'Apenas Flex',
    FIELD_TYPE_ALL_DDC_FREE_CLIENTS: 'Apenas Grátis',
    FIELD_TYPE_ALL_DDC_CLIENTES_WITHOUT_DELIVERY: 'Sem entrega',
    ADD_NEW_TITLE: 'Adicionar Regra de Inclusão/Exclusão',
    ADD_NEW_FIELD_ddc: 'Unidades',
    ADD_NEW_FIELD_ddc_ALL: 'Escolher Todas as Unidades',
    ADD_NEW_FIELD_client: 'PDVs',
    ADD_NEW_FIELD_client_ALL: 'Escolher todos os PDVs',
    ADD_NEW_DATE_FORMAT: 'dd/MM/yyyy',
    ADD_NEW_FIELD_DATE: 'Data',
    ADD_NEW_SUBMIT: 'Incluir',
    FILTER_TITLE: 'Buscar:',
    FILTER_DATE_FORMAT: 'dd/MM/yyyy',
    FILTER_START_DATE: 'Data inicial',
    FILTER_END_DATE: 'Data final',
    FILTER_SUBMIT: 'Buscar',
    UNABLE_TO_LOAD_LIST: 'Erro ao carregar a lista de regras',
    UNABLE_TO_SAVE_RULES: 'Erro ao enviar as Regras',
    SAVING_ERROR_WARNING_1:
      'Uma ou mais regras não puderam ser salvas. Veja os detalhes passando o mouse sobre ícone “',
    SAVING_ERROR_WARNING_2: '” nas linhas marcadas',
    SAVING_ERROR_INVALIDTIMEZONE: 'Unidade sem Fuso Horário',
    SAVING_ERROR_LOCALEXPIRATIONDATE: 'Data de expiração inválida',
    SAVING_ERROR_FLEXIBLE: 'Tipo de Janela inválida',
    SAVING_ERROR_ALREADYEXISTS: 'Regra já existente',
    SAVING_ERROR_CLIENTNOTFOUND: 'Cliente não encontrado',
    SAVING_ERROR_DDCNOTFOUND: 'Unidade não encontrada',
    SAVING_ERROR_RULENOTFOUND: 'Regra não encontrada',
    SAVING_ERROR_DUPLICATEDPARAMS: 'Regra duplicada',
    SAVING_ERROR_EARLIERDATE: 'Regra com data anterior a agora',
    SAVING_ERROR_DAYOFWEEKERROR:
      'Os valores de Pedido mínimo e Taxa de entrega não estão configurados para este dia',
    ADD_ERROR_DUPLICATED: 'Uma ou mais regras não foram adicionadas pois estão duplicadas',
    ADD_ERROR_VALIDATIONFAILED: 'Erro na validação dos dados',
  },
  ComplexFilter: {
    BUTTON_CLEAR_ALL: 'Limpar todos',
    BUTTON_CANCEL: 'Cancelar',
    BUTTON_APPLY: 'Aplicar',
    BUTTON_OPEN_FILTER: 'Filtrar por',
    DDCS_TITLE: 'Unidades',
    DDCS_SEARCH_PLACEHOLDER: 'Buscar por nome ou cód. da unidade',
    AUTOBLOCK_SITUATION_TITLE: 'Avaliação',
    [`AUTOBLOCK_SITUATION_${AutoblockSituationFilterOptions.allGood}`]: 'Normal',
    [`AUTOBLOCK_SITUATION_${AutoblockSituationFilterOptions.warning}`]: 'Precisa de atenção',
    [`AUTOBLOCK_SITUATION_${AutoblockSituationFilterOptions.blocked}`]: 'Bloqueado',
    AUTOBLOCK_CAPACITY_REACHED_TITLE: 'Capacidade atingida (%)',
    AUTOBLOCK_CAPACITY_REACHED_MIN: 'Mínimo',
    AUTOBLOCK_CAPACITY_REACHED_MAX: 'Máximo',
    PERIOD_TITLE: 'Período',
    PERIOD_START: 'Data inicial',
    PERIOD_END: 'Data final',
    PERIOD_TAG: 'De {start} até {end}',
    PERIOD_DATEFORMAT: 'dd MMM, yyyy',
    IMPORTS_EXPORTS_STATUS_TITLE: 'Status',
  },
  Autoblock: {
    STRATEGIES: 'Estratégias',
    CAPACITY_IN: 'Capacidade em {value}%',
    CAPACITY_OVER: 'Capacidade acima de {value}%',
    SUBGROUP: 'Grupo de PoCs: {value}',
    INCREASED_BY: 'Aumentado em {value}%',
    [`STRATEGY_TYPE_${StrategyType.Block}`]: 'Bloquear janela de entrega',
    [`STRATEGY_TYPE_${StrategyType.IncreaseAddAmount}`]: 'Aumentar taxa de entrega',
    [`STRATEGY_TYPE_${StrategyType.IncreaseMinValue}`]: 'Aumentar pedido mínimo',
    [`STRATEGY_TYPE_${StrategyType.TransformToFlex}`]: 'Transformar janela em Flex',
    [`STRATEGY_TYPE_${StrategyType.TransformToFree}`]: 'Transformar janela em Grátis',
    [`STRATEGY_SUBGROUP_${DdcGroup.All}`]: 'Todos',
    [`STRATEGY_SUBGROUP_${DdcGroup.Flex}`]: 'Apenas flex',
    [`STRATEGY_SUBGROUP_${DdcGroup.Free}`]: 'Apenas grátis',
    [`STRATEGY_SUBGROUP_${DdcGroup.NoDelivery}`]: 'Sem entrega',
  },
  AutoblockProgress: {
    TABTITLE: 'Progresso de estratégias',
    BREADCRUMB: 'Parâmetros de disponibilidade de capacidade de entrega',
    TITLE: 'Parâmetros de disponibilidade de capacidade de entrega',
    DATAGRID_COLUMN_DDC_NAME: 'Centro de distribuição',
    DATAGRID_COLUMN_LAST_UPDATED: 'Última atualização',
    DATAGRID_COLUMN_STRATEGY_COUNT: 'Estratégias ativas',
    DATAGRID_COLUMN_ASSESSMENT: 'Avaliação',
    DATEFORMAT: 'dd MMM, yyyy',
    ACTIVATION_DATETIMEFORMAT: 'EEE, dd MMM, yyyy, HH:mm',
    LAST_UPDATED_TEXT: '{date} por {user}',
    UNABLE_TO_LOAD_DETAILS: 'Erro ao carregar os detalhes.',
    DETAILS_DATEFORMAT: 'eeee, dd MMM, yyyy',
    DETAILS_CARD_OBSERVATION_TITLE: 'Capacidade',
    DETAILS_CARD_OBSERVATION:
      'Exibindo cartões apenas para dias em que a capacidade atingida é superior a 5%.',
    DETAILS_CARD_CREATED_BY: 'Criado por',
    DETAILS_CARD_CAPACITY_REACHED: '{visits} de {visitLimit} visitas atingidas',
    DETAILS_ACTIVE_STRATEGY: 'Estratégia ativa',
    DETAILS_VIEW_DETAILS: 'Mostrar detalhes',
    DETAILS_CARD_LOAD_MORE: 'Carregar mais',
    DETAILS_PLANNED_STRATEGIES: 'Estratégias planejadas',
  },
  AutoblockCreate: {
    TITLE: 'Parâmetro de disponibilidade',
    BREADCRUMB: 'Parâmetro de disponibilidade',
    DDCS_TITLE: 'CDs',
    DDCS_TEXT: 'Selecione os CDs para as quais este parâmetro de disponibilidade será aplicado.',
    PERIOD_TITLE: 'Período',
    PERIOD_TEXT:
      'Você pode selecionar um ou mais dias da semana, ou definir uma data específica para aplicar o parâmetro de disponibilidade',
    RECURRENCE_LABEL: 'Recorrência',
    RADIO_DAYS_OF_WEEK_LABEL: 'Dia(s) da semana',
    RADIO_SPECIFIC_DATE_LABEL: 'Data específica',
    SPECIFIC_DATE_LABEL: 'Data',
    DAYS_OF_WEEK_LABEL: 'Dia(s) da semana',
    SPECIFIC_DATE_FORMAT: 'dd/MM/yyyy',
    LIMIT_TITLE: 'Número máximo de visitas do CD',
    LIMIT_TEXT: 'Se o limite for atingido, a janela de entrega será bloqueada',
    LIMIT_INPUT_PLACEHOLDER: 'Informe o limite',
    CHANNELS_LABEL: 'Canais de pedidos',
    CHANNELS_PLACEHOLDER: 'Selecione um ou mais canais',
    CHANNELS_TEXT:
      'Você pode selecionar um ou mais canais de pedido que serão considerados no cálculo de consumo da capacidade.',
    [`CHANNELS_OPTION_${AutoblockChannels.BeesCustomerApp}`]: 'BEES Customer App',
    [`CHANNELS_OPTION_${AutoblockChannels.BeesCustomerWeb}`]: 'BEES Customer Web',
    [`CHANNELS_OPTION_${AutoblockChannels.BeesForce}`]: 'BEES Force',
    [`CHANNELS_OPTION_${AutoblockChannels.BeesGrow}`]: 'BEES Grow',
    [`CHANNELS_OPTION_${AutoblockChannels.BeesLink}`]: 'BEES Link',
    [`CHANNELS_OPTION_${AutoblockChannels.NonBees}`]: 'Pedidos não BEES',
    [`CHANNELS_OPTION_${AutoblockChannels.Other}`]: 'Outros canais',
    STRATEGIES_TITLE: 'Estratégias',
    STRATEGIES_TEXT: 'Defina estratégias para serem aplicadas de acordo com a capacidade do DC',
    STRATEGIES_DC_CAPACITY_LABEL: 'Capacidade do DC (%)',
    STRATEGIES_TYPE_LABEL: 'Estratégia',
    STRATEGIES_SUBGROUP_LABEL: 'Aplicar para PoCs',
    STRATEGIES_VALUE_INCREASE_LABEL: 'Aumentar em (%)',
    STRATEGIES_ADD_BUTTON: 'Adicionar estratégia',
    LEAVE_MODAL_TITLE: 'Abandonar alterações?',
    LEAVE_MODAL_TEXT: 'Você perderá todas as informações.',
    LEAVE_MODAL_CONFIRM: 'Sim, cancelar',
    LEAVE_MODAL_CANCEL: 'Não, continuar',
    CONFIRM_BUTTON: 'Salvar',
    CONFIRM_MODAL_TITLE: 'Salvar parâmetro de disponibilidade?',
    CONFIRM_MODAL_TEXT:
      'As estratégias serão implementadas de acordo com a capacidade do CD, na seguinte ordem.',
    TOAST_SUCCESS: 'Parâmetro(s) de disponibilidade salvo(s) com sucesso',
    TOAST_FAILURE: 'Erro ao criar Parâmetro(s) de disponibilidade. Confira os detalhes abaixo',
    TOAST_MIXED: 'Um ou mais Auto bloqueios não puderam ser salvos. Confira os detalhes abaixo.',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.validationFailed}`]:
      'CD {ddcCode}: ocorreu um erro ao salvar o parâmetro de disponibilidade de capacidade',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.notFound}`]:
      'CD {ddcCode}: parâmetro de disponibilidade de capacidade não encontrado',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.earlierDate}`]:
      'CD {ddcCode}: não é possível cadastrar um parâmetro de disponibilidade de capacidade para uma nada menor que o dia atual',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.alreadyExists}`]:
      'CD {ddcCode}: já existe um parâmetro de disponibilidade de capacidade cadastrado para esse dia ',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.ddcNotFound}`]:
      'CD {ddcCode}: o centro de distribuição não existe ou não está ativo no Delivery Admin ',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.maxStrategiesReached}`]:
      'CD {ddcCode}: podem ser adicionadas no máximo 10 estratégias por parâmetro de disponibilidade de capacidade',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.invalidPercentage}`]:
      'CD {ddcCode}: a porcentagem informada na estratégia está inválida ',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.duplicatedPercentage}`]:
      'CD {ddcCode}: há mais de uma estratégia com o mesmo percentual',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.mandatoryIncreaseValue}`]:
      'CD {ddcCode}: é obrigatório preencher o vampo de percentual de aumento para estratégias de aumento de FEE ou MOV',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.invalidIncreaseValue}`]:
      'CD {ddcCode}: a porcentagem informada na estratégia de aumento de FEE ou MOV está inválida',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.invalidSubgroup}`]:
      'CD {ddcCode}: um ou mais subgrupos selecionados nas estratégias estão inválidos',
    SUCCESS_MESSAGE:
      'CD {ddcCode}: parâmetro de disponibilidade de capacidade adicionado com sucesso',
    TOAST_ERROR_LOADING_AUTOBLOCK: 'Erro ao carregar o parâmetro de disponibilidade',
  },
  errorNoVendor: {
    BREADCRUMB: 'Acesso negado',
    DESCRIPTION:
      'Parece que você não tem nenhum vendorId associado ao seu usuário. Abra um ticket para o time de suporte solicitando acesso ao vendorId que você precisa editar.',
    HEADER: 'Hmm... Acesso negado!',
  },
  SellerSettings: {
    TITLE: 'Configurações do seller',
    BREADCRUMB: 'Configurações do seller',
    VENDOR_ID_LABEL: 'ID do Vendedor',
    DELIVERY_FEATURES_TAB_TITLE: 'Features de entrega',
    DELIVERY_FEATURES_TAB_TEXT:
      'Habilite um ou mais recursos de entrega para centros de distribuição.',
    [`FEATURE_${FeatureActivationItems.regularDeliveryEnabled}_TITLE`]:
      'Datas de entrega regulares',
    [`FEATURE_${FeatureActivationItems.regularDeliveryEnabled}_TEXT`]:
      'Entregas em datas regulares são gratuitas.',
    [`FEATURE_${FeatureActivationItems.flexDeliveryEnabled}_TITLE`]: 'Datas de entrega flexíveis',
    [`FEATURE_${FeatureActivationItems.flexDeliveryEnabled}_TEXT`]:
      'Entregas em datas flexíveis têm uma taxa extra.',
    [`FEATURE_${FeatureActivationItems.clickCollectEnabled}_TITLE`]: 'Click & Collect',
    [`FEATURE_${FeatureActivationItems.clickCollectEnabled}_TEXT`]:
      'Clientes podem coletar seus pedidos no local.',
    UPDATE_DELIVERY_FEATURE_SUCCESS: 'Feature de entrega atualizado com sucesso',
    UPDATE_DELIVERY_FEATURE_ERROR:
      'Erro ao atualizar a feature de entrega. Por favor, tente novamente mais tarde.',
  },
};

export default br;
